import { Divider } from 'antd';
import { AccountFilter } from 'features/waAccounts/components/accountFilter';
import { memo } from 'react';
import { FilterIcons } from 'shared/components/filterIcons/filterIcons';
import { useConnectionSessionWACount } from './__generated__/ConnectionSessionWACountQuery';

export const AccountFiltersList = memo(() => {
  const { data, loading } = useConnectionSessionWACount({ pollInterval: 5000 });

  if (loading) return <></>;
  return (
    <div className="my-2 flex grid-cols-2 flex-col gap-2 2xl:grid">
      {data?.connectionSessionWACount?.map((item, index) => {
        if (
          ['PC_01', 'PC_02', 'PC_03', 'PC_04', 'PC_05', 'PC_06', 'PC_07', 'PC_08'].includes(
            item?.host || ''
          )
        )
          return (
            <AccountFilter
              key={index}
              linkFilter={item?.host || ''}
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['Phones'].includes(item?.host || ''))
          return (
            <AccountFilter
              className="col-span-2"
              key={index}
              linkFilter={item?.host || ''}
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['WMMAIL', 'PROGREV', 'PROD', 'NEW'].includes(item?.status || ''))
          return (
            <AccountFilter
              key={index}
              linkFilter={
                FilterIcons[item?.status as keyof typeof FilterIcons] + item?.status || ''
              }
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['IT', 'BOSS', 'POISK'].includes(item?.status || ''))
          return (
            <AccountFilter
              key={index}
              linkFilter={
                FilterIcons[item?.status as keyof typeof FilterIcons] + item?.status || ''
              }
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['SAFRONOV', 'LOGINOV', 'YASHIN', 'LIPATOV'].includes(item?.status || ''))
          return (
            <AccountFilter
              key={index}
              linkFilter={FilterIcons['PEOPLE'] + item?.status || ''}
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['HARAMETSCKY'].includes(item?.status || ''))
          return (
            <AccountFilter
              className="col-span-2"
              key={index}
              linkFilter={FilterIcons['PEOPLE'] + item?.status || ''}
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      <Divider className="col-span-2 my-1 dark:bg-dividerDark" />
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['NOVOREG'].includes(item?.status || ''))
          return (
            <AccountFilter
              className="col-span-2"
              key={index}
              linkFilter={
                FilterIcons[(item?.status as keyof typeof FilterIcons) || 'BANNED'] +
                  item?.status || ''
              }
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['BANNED', 'QR'].includes(item?.status || ''))
          return (
            <AccountFilter
              className="col-span-2"
              key={index}
              linkFilter={
                FilterIcons[(item?.status as keyof typeof FilterIcons) || 'BANNED'] +
                  item?.status || ''
              }
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
            />
          );
      })}
      {data?.connectionSessionWACount?.map((item, index) => {
        if (['TH'].includes(item?.status || ''))
          return (
            <AccountFilter
              key={index}
              linkFilter={'🤠TRUCKHOLDING'}
              activeAccounts={item?.activeCount || 0}
              allAccounts={item?.allCount || 0}
              className="col-span-2"
            />
          );
      })}
    </div>
  );
});
