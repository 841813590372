import { useFilter } from 'app/context/filterContext';
import { motion } from 'framer-motion';

const TimezoneFilters = () => {
  const { timezoneFilter, setTimezoneFilter } = useFilter();

  const timezoneList = [
    { value: 'UTC+3', label: 'UTC+3' },
    { value: 'UTC+4', label: 'UTC+4' },
    { value: 'UTC+5', label: 'UTC+5' },
    { value: 'UTC+7', label: 'UTC+7' },
    { value: 'UTC+8', label: 'UTC+8' },
    { value: 'UTC+9', label: 'UTC+9' },
    { value: 'UTC+10', label: 'UTC+10' },
    { value: 'UTC+11', label: 'UTC+11' },
  ];

  return (
    <motion.div
      key="version-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {timezoneList.map((timezone) => (
        <button
          key={timezone.label}
          onClick={() => {
            timezoneFilter === timezone.value
              ? setTimezoneFilter('')
              : setTimezoneFilter(timezone.value);
          }}
          className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${timezoneFilter === timezone.value ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
        >
          <p
            className={`text-sm font-medium ${timezoneFilter === timezone.value ? 'text-white' : 'dark:text-white'}`}
          >
            {timezone.label}
          </p>
        </button>
      ))}
    </motion.div>
  );
};

export default TimezoneFilters;
