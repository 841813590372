import { createContext, ReactNode, useContext, useState } from 'react';

interface SelectedAccountContext {
  selectedAccount: string | null | undefined;
  setSelectedAccount: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}

export const SelectedAccountContext = createContext<SelectedAccountContext>({
  selectedAccount: null,
  setSelectedAccount: () => {},
});

export const SelectedAccountProvider = ({ children }: { children: ReactNode }) => {
  const [selectedAccount, setSelectedAccount] = useState<string | null | undefined>(null);

  return (
    <SelectedAccountContext.Provider value={{ selectedAccount, setSelectedAccount }}>
      {children}
    </SelectedAccountContext.Provider>
  );
};

export const useSelectedAccount = () => {
  const context = useContext(SelectedAccountContext);
  if (!context) {
    throw new Error('useSelectedAccount должен использоваться в SelectedAccountProvider');
  }
  return context;
};
