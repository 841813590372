import { SignedIn, SignedOut, SignInButton, UserButton } from '@clerk/clerk-react';
import { useConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Logo, LogoMobile } from 'shared/assets/icons/icons';
import HostFilters from 'shared/components/filters/hostFilters';
import TimezoneFilters from 'shared/components/filters/timezoneFilters';
import VersionFilters from 'shared/components/filters/versionFilters';
import VpsFilters from 'shared/components/filters/vpsFilters';
import FilterTumbler from 'shared/components/filterTumbler/filterTumbler';
import ThemeTumbler from 'shared/components/themeTumbler/ui/themeTumbler';

export const Header = () => {
  const location = useLocation();
  const isAuthorizePage = location.pathname.includes('authorize');
  const isBroadcastPage =
    location.pathname.includes('broadcast') &&
    !location.pathname.includes('create') &&
    !location.pathname.includes('edit');

  const { data: accs } = useConnectionSessionWAList({ variables: { filter: {} } });
  const [filter, setFilter] = useState<'host' | 'vps' | 'version'>('host');

  const versions = accs?.connectionSessionWAList?.reduce(
    (acc, current) => {
      const version = current?.versionCode ?? '';
      if (version) {
        acc[version] = (acc[version] || 0) + 1;
      }
      return acc;
    },
    {} as Record<string, number>
  );

  return (
    <header className="flex h-[100px] w-full flex-row items-center justify-between px-[6%] lg:px-[1%]">
      <div className="flex flex-row items-center gap-x-4">
        <Link to="/">
          <Logo className="hidden md:block" />
          <LogoMobile className="block size-12 md:hidden" />
        </Link>
        {isAuthorizePage && (
          <div className="flex flex-row items-center gap-3">
            <FilterTumbler filter={filter} setFilter={setFilter} />
            {filter === 'host' ? (
              <HostFilters accs={accs} />
            ) : filter === 'vps' ? (
              <VpsFilters accs={accs} />
            ) : (
              <VersionFilters accs={accs} versions={versions} />
            )}
          </div>
        )}
        {isBroadcastPage && <TimezoneFilters />}
      </div>
      <div className="ml-4 flex flex-row items-center gap-x-4">
        <ThemeTumbler />
        <SignedOut>
          <SignInButton />
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </div>
    </header>
  );
};

export default Header;
