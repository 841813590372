import { AuthorizePageLazy } from 'pages/authorizePage/authorizePageLazy';
import { BaseCreatePageLazy } from 'pages/baseCreate/baseCreatePageLazy';
import { BestOfferPageLazy } from 'pages/bestOffer/bestOfferPageLazy';
import { BroadcastPageLazy } from 'pages/broadcastPage/broadcastPageLazy';
import { NodesPageLazy } from 'pages/nodesPage/nodesPageLazy';
import { TypificationPageLazy } from 'pages/typificationPage/typificationPageLazy';
import { WaChatsPageLazy } from 'pages/waChatsPage/waChatsPageLazy';
import { WithoutRoleUserPageLazy } from 'pages/withoutRoleUser/withoutRoleUserPageLazy';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const AppRouter = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path="/" element={<Navigate to="/wachats" />} />
        <Route path="/wachats/:chat?" element={<WaChatsPageLazy />} />
        <Route path="/broadcast/:broadcastid?/:client?" element={<BroadcastPageLazy />} />
        <Route path="/broadcast/:broadcastid/edit" element={<BroadcastPageLazy />} />
        <Route path="/broadcast/create" element={<BroadcastPageLazy />} />
        <Route path="typification" element={<TypificationPageLazy />} />
        <Route path="best-offer" element={<BestOfferPageLazy />} />
        <Route path="base-create" element={<BaseCreatePageLazy />} />
        <Route path="nodes" element={<NodesPageLazy />}>
          <Route path=":selectednode" element={<NodesPageLazy />} />
        </Route>
        <Route path="authorize" element={<AuthorizePageLazy />} />
        <Route
          path="/access-denied"
          element={
            !localStorage.getItem('userRole') ? <WithoutRoleUserPageLazy /> : <Navigate to="/" />
          }
        />
      </Routes>
    </Suspense>
  );
};
