import { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export const NavLink = memo((props: Props) => {
  const { href, children, className } = props;

  const pathname = useLocation().pathname;
  const isActive = pathname.includes(href) || pathname === href;
  return (
    <Link to={href} className={`${className} button ${isActive ? 'activeLink' : 'inactiveLink'}`}>
      {children}
    </Link>
  );
});
