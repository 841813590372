import { memo, useEffect, useState } from 'react';
import { Moon, Sun } from 'shared/assets/icons/icons';

export const ThemeTumbler = memo(() => {
  const [isDark, setIsDark] = useState(localStorage.getItem('theme') === 'dark');

  const enableDarkTheme = () => {
    setIsDark(true);
  };

  const enableLightTheme = () => {
    setIsDark(false);
  };

  useEffect(() => {
    localStorage.setItem('theme', isDark ? 'dark' : 'light');
    document.documentElement.classList.toggle('dark', isDark);
  }, [isDark]);

  return (
    <div className="relative flex h-10 flex-row items-center gap-x-4 rounded-full bg-surfaceDarker px-2 shadow-sm dark:bg-surfaceDark">
      <Moon
        className={`${
          isDark ? '' : 'rotate-45 opacity-50'
        } relative z-20 size-6 cursor-pointer transition-all duration-200`}
        onClick={enableDarkTheme}
      />
      <Sun
        className={`${
          isDark ? 'rotate-45 opacity-50' : ''
        } relative z-20 size-6 cursor-pointer transition-all duration-200`}
        onClick={enableLightTheme}
      />
      <div
        className={`absolute z-10 aspect-square h-[80%] rounded-full bg-activeLinkLight transition-transform duration-300 ease-in-out dark:bg-activeLinkDark ${
          isDark ? '-translate-x-1' : 'translate-x-[2.2rem]'
        }`}
      />
    </div>
  );
});

export default ThemeTumbler;
