import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface SelectedClientContext {
  selectedClient: string | null | undefined;
  setSelectedClient: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  singleClient: boolean;
}

export const SelectedClientContext = createContext<SelectedClientContext>({
  selectedClient: null,
  setSelectedClient: () => {},
  singleClient: false,
});

export const SelectedClientProvider = ({ children }: { children: ReactNode }) => {
  const [selectedClient, setSelectedClient] = useState<string | null | undefined>(null);
  const [singleClient, setSingleClient] = useState<boolean>(false);

  useEffect(() => {
    setSingleClient(!!selectedClient);
  }, [selectedClient]);

  return (
    <SelectedClientContext.Provider value={{ selectedClient, setSelectedClient, singleClient }}>
      {children}
    </SelectedClientContext.Provider>
  );
};

export const useSelectedClient = () => {
  const context = useContext(SelectedClientContext);
  if (!context) {
    throw new Error('useSelectedClient должен использоваться в SelectedClientProvider');
  }
  return context;
};
