import { SignIn, useUser } from '@clerk/clerk-react';
import Layout from 'app/layout';
import { AppRouter } from 'app/appRouter';
import { motion } from 'framer-motion';
import { Loading } from 'shared/assets/icons/icons';
import { FilterProvider } from 'app/context/filterContext';
import { UserProvider } from './context/userContext';
import { FullscreenProvider } from './context/fullscreenContext';
import { SelectedClientProvider } from './context/selectedClientContext';
import { SelectedAccountProvider } from './context/selectedAccountContext';

const App = () => {
  const { user, isLoaded } = useUser();
  if (!isLoaded)
    return (
      <div className="flex h-screen items-center justify-center bg-surfaceDark">
        <motion.span
          animate={{ rotate: 360 }}
          transition={{ duration: 1.4, repeat: Infinity, repeatType: 'loop', ease: 'linear' }}
        >
          <Loading className="size-32" />
        </motion.span>
      </div>
    );

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center">
        <SignIn />
      </div>
    );
  }
  return (
    <SelectedAccountProvider>
      <SelectedClientProvider>
        <UserProvider>
          <FilterProvider>
            <FullscreenProvider>
              <Layout>
                <AppRouter />
              </Layout>
            </FullscreenProvider>
          </FilterProvider>
        </UserProvider>
      </SelectedClientProvider>
    </SelectedAccountProvider>
  );
};

export default App;
