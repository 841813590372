import { UserCountByType } from '__generated__/types';
import { memo } from 'react';
import { useFilter } from 'app/context/filterContext';

interface Props {
  type: string;
  allCount?: number | null | undefined;
  clientsTypeCount?: UserCountByType | null | undefined;
  className?: string;
}

const TypificationFilters = memo((props: Props) => {
  const { type, clientsTypeCount, allCount, className } = props;

  const akCount = clientsTypeCount?.akCount || 0;
  const clientCount = clientsTypeCount?.clientCount || 0;
  const dealerCount = clientsTypeCount?.dealerCount || 0;
  const molchunCount = clientsTypeCount?.molchunCount || 0;
  const mopLkCount = clientsTypeCount?.mopLkCount || 0;
  const noNameCount = clientsTypeCount?.noNameCount || 0;
  const partsCount = clientsTypeCount?.partsCount || 0;
  const rabotaCount = clientsTypeCount?.rabotaCount || 0;
  const spamCount = clientsTypeCount?.spamCount || 0;
  const torgashCount = clientsTypeCount?.torgashCount || 0;

  const { typificationFilter, setTypificationFilter } = useFilter();
  const handleFilterClick = (newFilter: string) => {
    setTypificationFilter(newFilter);
  };

  let count = 0;
  if (type === 'AK') {
    count = akCount;
  } else if (type === 'CLIENT') {
    count = clientCount;
  } else if (type === 'DEALER') {
    count = dealerCount;
  } else if (type === 'MOLCHUN') {
    count = molchunCount;
  } else if (type === 'MOP_LK') {
    count = mopLkCount;
  } else if (type === 'NO_NAME') {
    count = noNameCount;
  } else if (type === 'PARTS') {
    count = partsCount;
  } else if (type === 'RABOTA') {
    count = rabotaCount;
  } else if (type === 'SPAM') {
    count = spamCount;
  } else if (type === 'TORGASH') {
    count = torgashCount;
  }

  return (
    <button
      onClick={() => handleFilterClick(type)}
      className={`button ${className} flex flex-col ${typificationFilter === type ? 'bg-activeLinkLight dark:bg-activeLinkDark' : 'bg-surfaceLight dark:bg-surfaceLighter'}`}
    >
      <p className="leading-none">{type}</p>
      <p className="text-[10px] leading-none">{count || allCount}</p>
    </button>
  );
});

export default TypificationFilters;
