import { ClientStatus } from '__generated__/types';
import { Badge, ConfigProvider } from 'antd';
import { useFilter } from 'app/context/filterContext';
import { ConnectionSessionWAList } from 'features/waAccounts/__generated__/ConnectionSessionWAListQuery';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { themeIsDark } from 'utils/appTheme';

interface Props {
  accs: ConnectionSessionWAList | undefined;
  versions: Record<string, number> | undefined;
}

const VersionFilters = ({ accs, versions }: Props) => {
  const { versionFilter, setVersionFilter, authFilter } = useFilter();

  const [, setFilteredAccounts] = useState<(ClientStatus | null)[] | null | undefined>();

  useEffect(() => {
    const filteredAccounts = accs?.connectionSessionWAList
      ?.filter((acc) =>
        authFilter.toLowerCase()?.includes(acc?.waClient?.status?.toString()?.toLowerCase() || '')
      )
      .map((acc) => ({ ...acc, _id: acc?.waClient?._id || '' }));
    setFilteredAccounts(filteredAccounts || null);
  }, [accs, authFilter]);

  return (
    <motion.div
      key="version-filters"
      animate={{ opacity: [0, 1], gap: [0, '8px'] }}
      transition={{ duration: 0.3, delay: 0.2 }}
      className="flex flex-row items-center"
    >
      {Object.entries(versions || {})
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([key, value], index) => (
          <ConfigProvider
            key={index}
            theme={{
              token: {
                colorBorderBg: 'transparent',
                fontSize: 13,
                colorBgBase: themeIsDark ? 'white' : 'black',
              },
              components: {
                Badge: {
                  indicatorZIndex: 20,
                },
              },
            }}
          >
            <Badge key={key} count={value} color={themeIsDark ? '#505b65' : '#eaf5ff'}>
              <button
                key={key}
                onClick={() => {
                  versionFilter === key ? setVersionFilter('') : setVersionFilter(key);
                }}
                className={`group flex select-none items-center justify-center rounded-lg duration-150 dark:text-white ${versionFilter === key ? 'bg-green-600' : 'bg-surfaceDarker hover:bg-green-200 dark:bg-surfaceDark dark:hover:bg-green-600/40'} gap-2 px-3 py-1`}
              >
                <p
                  className={`text-sm font-medium ${versionFilter === key ? 'text-white' : 'dark:text-white'}`}
                >
                  {key}
                </p>
              </button>
            </Badge>
          </ConfigProvider>
        ))}
    </motion.div>
  );
};

export default VersionFilters;
