import { motion } from 'framer-motion';
import { SVGProps } from 'react';

interface IconProps extends SVGProps<SVGSVGElement> {
  className?: string;
  title?: string;
}

export const Messanger = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.82999 19.9C7.69999 19.73 7.41 19.58 7.19 19.58H6.79001C3.60001 19.58 2 18.79 2 14.79V10.79C2 7.86001 3.35001 6.27001 6.04001 6.04001C6.28001 6.01001 6.53001 6 6.79001 6H13.19C16.38 6 17.98 7.60001 17.98 10.79Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M9.99023 14C9.43023 14 8.99023 13.55 8.99023 13C8.99023 12.45 9.44023 12 9.99023 12C10.5402 12 10.9902 12.45 10.9902 13C10.9902 13.55 10.5502 14 9.99023 14Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M13.4902 14C12.9302 14 12.4902 13.55 12.4902 13C12.4902 12.45 12.9402 12 13.4902 12C14.0402 12 14.4902 12.45 14.4902 13C14.4902 13.55 14.0402 14 13.4902 14Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M6.5 14C5.94 14 5.5 13.55 5.5 13C5.5 12.45 5.95 12 6.5 12C7.05 12 7.5 12.45 7.5 13C7.5 13.55 7.05 14 6.5 14Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M21.9791 6.79001V10.79C21.9791 13.73 20.6291 15.31 17.9391 15.54C17.9691 15.3 17.9791 15.05 17.9791 14.79V10.79C17.9791 7.60001 16.3791 6 13.1891 6H6.78906C6.52906 6 6.27906 6.01001 6.03906 6.04001C6.26906 3.35001 7.85906 2 10.7891 2H17.1891C20.3791 2 21.9791 3.60001 21.9791 6.79001Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Broadcast = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M11.9991 14.3801C13.3136 14.3801 14.3792 13.3146 14.3792 12.0001C14.3792 10.6857 13.3136 9.62012 11.9991 9.62012C10.6847 9.62012 9.61914 10.6857 9.61914 12.0001C9.61914 13.3146 10.6847 14.3801 11.9991 14.3801Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.8"
        d="M20.0003 18.75C19.8403 18.75 19.6903 18.7 19.5503 18.6C19.2203 18.35 19.1503 17.88 19.4003 17.55C20.6103 15.94 21.2503 14.02 21.2503 12C21.2503 9.98004 20.6103 8.06005 19.4003 6.45005C19.1503 6.12005 19.2203 5.65003 19.5503 5.40003C19.8803 5.15003 20.3503 5.22002 20.6003 5.55002C22.0103 7.42002 22.7503 9.65004 22.7503 12C22.7503 14.35 22.0103 16.58 20.6003 18.45C20.4503 18.65 20.2303 18.75 20.0003 18.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.8"
        d="M4 18.75C3.77 18.75 3.54999 18.65 3.39999 18.45C1.98999 16.58 1.25 14.35 1.25 12C1.25 9.65004 1.98999 7.42002 3.39999 5.55002C3.64999 5.22002 4.12001 5.15003 4.45001 5.40003C4.78001 5.65003 4.85001 6.12005 4.60001 6.45005C3.39001 8.06005 2.75 9.98004 2.75 12C2.75 14.02 3.39001 15.94 4.60001 17.55C4.85001 17.88 4.78001 18.35 4.45001 18.6C4.32001 18.7 4.16 18.75 4 18.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.6"
        d="M16.7991 16.3499C16.6391 16.3499 16.4891 16.2999 16.3491 16.1999C16.0191 15.9499 15.9491 15.4799 16.1991 15.1499C16.8891 14.2399 17.2491 13.1499 17.2491 11.9999C17.2491 10.8499 16.8891 9.75994 16.1991 8.84994C15.9491 8.51994 16.0191 8.04992 16.3491 7.79992C16.6791 7.54992 17.1491 7.61995 17.3991 7.94995C18.2791 9.12995 18.7491 10.5299 18.7491 11.9999C18.7491 13.4699 18.2791 14.8799 17.3991 16.0499C17.2491 16.2499 17.0291 16.3499 16.7991 16.3499Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.6"
        d="M7.20001 16.3499C6.97001 16.3499 6.75001 16.2499 6.60001 16.0499C5.72001 14.8699 5.25 13.4699 5.25 11.9999C5.25 10.5299 5.72001 9.11995 6.60001 7.94995C6.85001 7.61995 7.31999 7.54992 7.64999 7.79992C7.97999 8.04992 8.04999 8.51994 7.79999 8.84994C7.10999 9.75994 6.75 10.8499 6.75 11.9999C6.75 13.1499 7.10999 14.2399 7.79999 15.1499C8.04999 15.4799 7.97999 15.9499 7.64999 16.1999C7.51999 16.2999 7.36001 16.3499 7.20001 16.3499Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Pen = (props: IconProps) => (
  <svg
    {...props}
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M15.4998 5.49994L18.3282 8.32837M3 20.9997L3.04745 20.6675C3.21536 19.4922 3.29932 18.9045 3.49029 18.3558C3.65975 17.8689 3.89124 17.4059 4.17906 16.9783C4.50341 16.4963 4.92319 16.0765 5.76274 15.237L17.4107 3.58896C18.1918 2.80791 19.4581 2.80791 20.2392 3.58896C21.0202 4.37001 21.0202 5.63634 20.2392 6.41739L8.37744 18.2791C7.61579 19.0408 7.23497 19.4216 6.8012 19.7244C6.41618 19.9932 6.00093 20.2159 5.56398 20.3879C5.07171 20.5817 4.54375 20.6882 3.48793 20.9012L3 20.9997Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const Ellipsis = (props: IconProps) => (
  <svg
    {...props}
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_iconCarrier">
      <path
        d="M12 13.75C12.9665 13.75 13.75 12.9665 13.75 12C13.75 11.0335 12.9665 10.25 12 10.25C11.0335 10.25 10.25 11.0335 10.25 12C10.25 12.9665 11.0335 13.75 12 13.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M19 13.75C19.9665 13.75 20.75 12.9665 20.75 12C20.75 11.0335 19.9665 10.25 19 10.25C18.0335 10.25 17.25 11.0335 17.25 12C17.25 12.9665 18.0335 13.75 19 13.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M5 13.75C5.9665 13.75 6.75 12.9665 6.75 12C6.75 11.0335 5.9665 10.25 5 10.25C4.0335 10.25 3.25 11.0335 3.25 12C3.25 12.9665 4.0335 13.75 5 13.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Exit = (props: IconProps) => (
  <svg
    {...props}
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.5"
        d="M9.052 4.5C9 5.07763 9 5.80355 9 6.72183V17.2781C9 18.1964 9 18.9224 9.05201 19.5H8C5.64298 19.5 4.46447 19.5 3.73223 18.7678C3 18.0355 3 16.857 3 14.5V9.5C3 7.14298 3 5.96447 3.73223 5.23223C4.46447 4.5 5.64298 4.5 8 4.5H9.052Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.70725 2.4087C9 3.03569 9 4.18259 9 6.4764V17.5236C9 19.8174 9 20.9643 9.70725 21.5913C10.4145 22.2183 11.4955 22.0297 13.6576 21.6526L15.9864 21.2465C18.3809 20.8288 19.5781 20.62 20.2891 19.7417C21 18.8635 21 17.5933 21 15.0529V8.94711C21 6.40671 21 5.13652 20.2891 4.25826C19.5781 3.37999 18.3809 3.17118 15.9864 2.75354L13.6576 2.34736C11.4955 1.97026 10.4145 1.78171 9.70725 2.4087ZM12.75 10.9535C12.75 10.52 12.4142 10.1686 12 10.1686C11.5858 10.1686 11.25 10.52 11.25 10.9535V13.0465C11.25 13.48 11.5858 13.8314 12 13.8314C12.4142 13.8314 12.75 13.48 12.75 13.0465V10.9535Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Stop = (props: IconProps) => (
  <svg
    {...props}
    width="64px"
    height="64px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.5"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M8.58579 8.58579C8 9.17157 8 10.1144 8 12C8 13.8856 8 14.8284 8.58579 15.4142C9.17157 16 10.1144 16 12 16C13.8856 16 14.8284 16 15.4142 15.4142C16 14.8284 16 13.8856 16 12C16 10.1144 16 9.17157 15.4142 8.58579C14.8284 8 13.8856 8 12 8C10.1144 8 9.17157 8 8.58579 8.58579Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const VPS = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M21 6V13.25C21 13.8023 20.5523 14.25 20 14.25H5C4.44772 14.25 4 13.8023 4 13.25V6C4 3.79 5.79 2 8 2H17C19.21 2 21 3.79 21 6Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M8 12.75H7C6.59 12.75 6.25 12.41 6.25 12C6.25 11.59 6.59 11.25 7 11.25H8C8.41 11.25 8.75 11.59 8.75 12C8.75 12.41 8.41 12.75 8 12.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M8 10.25H7C6.59 10.25 6.25 9.91 6.25 9.5C6.25 9.09 6.59 8.75 7 8.75H8C8.41 8.75 8.75 9.09 8.75 9.5C8.75 9.91 8.41 10.25 8 10.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M8 7.75H7C6.59 7.75 6.25 7.41 6.25 7C6.25 6.59 6.59 6.25 7 6.25H8C8.41 6.25 8.75 6.59 8.75 7C8.75 7.41 8.41 7.75 8 7.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M5 15.75C4.44772 15.75 4 16.1977 4 16.75V18C4 20.21 5.79 22 8 22H17C19.21 22 21 20.21 21 18V16.75C21 16.1977 20.5523 15.75 20 15.75H5ZM17.71 19.46C17.52 19.64 17.26 19.75 17 19.75C16.74 19.75 16.48 19.64 16.29 19.46C16.11 19.27 16 19.01 16 18.75C16 18.49 16.11 18.23 16.29 18.04C16.66 17.67 17.33 17.67 17.71 18.04C17.89 18.23 18 18.49 18 18.75C18 19.01 17.89 19.27 17.71 19.46Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Accounts = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M10.9999 7.5V16.5C10.9999 17.05 10.5499 17.5 9.99989 17.5H7.49989C5.97989 17.5 4.60989 16.88 3.60989 15.89C2.66989 14.94 2.05989 13.65 1.99989 12.22C1.87989 9.08 4.61989 6.5 7.76989 6.5H9.99989C10.5499 6.5 10.9999 6.95 10.9999 7.5Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.4"
        d="M21.9998 11.78C22.1298 14.93 19.3898 17.5 16.2398 17.5H14.0098C13.4598 17.5 13.0098 17.05 13.0098 16.5V7.5C13.0098 6.95 13.4598 6.5 14.0098 6.5H16.5098C18.0298 6.5 19.3998 7.12 20.3998 8.11C21.3298 9.06 21.9398 10.35 21.9998 11.78Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M16 12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const ArrowRight = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12H20M20 12L16 8M20 12L16 16"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Configure = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Archive = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M14.7891 4H9.20906C4.78906 4 4.78906 6.35 4.78906 8.42V12.21C4.78906 12.43 4.88906 12.63 5.05906 12.76C5.22906 12.89 5.45906 12.94 5.66906 12.88C6.11906 12.76 6.67906 12.7 7.34906 12.7C8.01906 12.7 8.15906 12.78 8.55906 13.08L9.46906 14.04C10.1191 14.74 11.0491 15.14 12.0091 15.14C12.9691 15.14 13.8891 14.74 14.5491 14.04L15.4591 13.08C15.8591 12.78 15.9991 12.7 16.6691 12.7C17.3391 12.7 17.8991 12.76 18.3491 12.88C18.5591 12.94 18.7791 12.89 18.9591 12.76C19.1291 12.63 19.2291 12.42 19.2291 12.21V8.42C19.2091 6.35 19.2091 4 14.7891 4Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M14.2743 6.8C14.0143 6.54 13.5843 6.54 13.3243 6.8L12.6743 7.45V2.67C12.6743 2.3 12.3643 2 11.9943 2C11.6243 2 11.3143 2.3 11.3143 2.67V7.44L10.6743 6.8C10.4143 6.54 9.9843 6.54 9.7243 6.8C9.4643 7.06 9.4643 7.49 9.7243 7.75L11.5243 9.55C11.5343 9.56 11.5343 9.56 11.5443 9.56C11.6043 9.61 11.6643 9.66 11.7443 9.69C11.8243 9.72 11.9143 9.74 12.0043 9.74C12.0943 9.74 12.1743 9.72 12.2643 9.69C12.3443 9.66 12.4243 9.61 12.4843 9.54L14.2843 7.74C14.5343 7.49 14.5343 7.06 14.2743 6.8Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M18.6897 11.5298C18.1197 11.3798 17.4497 11.2998 16.6497 11.2998C15.5397 11.2998 15.1297 11.5698 14.5597 11.9998C14.5297 12.0198 14.4997 12.0498 14.4697 12.0798L13.5197 13.0898C12.7197 13.9298 11.2797 13.9398 10.4797 13.0798L9.52969 12.0798C9.49969 12.0498 9.46969 12.0198 9.43969 11.9998C8.86969 11.5698 8.45969 11.2998 7.34969 11.2998C6.54969 11.2998 5.87969 11.3798 5.30969 11.5298C2.92969 12.1698 2.92969 14.0598 2.92969 15.7198V16.6498C2.92969 19.1598 2.92969 21.9998 8.27969 21.9998H15.7197C19.2697 21.9998 21.0697 20.1998 21.0697 16.6498V15.7198C21.0697 14.0598 21.0697 12.1698 18.6897 11.5298ZM14.3297 18.3998H9.66969C9.28969 18.3998 8.97969 18.0898 8.97969 17.6998C8.97969 17.3098 9.28969 16.9998 9.66969 16.9998H14.3297C14.7097 16.9998 15.0197 17.3098 15.0197 17.6998C15.0197 18.0898 14.7097 18.3998 14.3297 18.3998Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.4"
        d="M15.0205 17.7C15.0205 18.09 14.7105 18.4 14.3305 18.4H9.67047C9.29047 18.4 8.98047 18.09 8.98047 17.7C8.98047 17.31 9.29047 17 9.67047 17H14.3305C14.7105 17 15.0205 17.31 15.0205 17.7Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const LinkIcon = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Loading = (props: IconProps) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3935 5.37371C18.0253 6.70569 19.8979 10.7522 18.5761 14.4118C17.6363 17.0135 15.335 18.7193 12.778 19.0094M12.778 19.0094L13.8253 17.2553M12.778 19.0094L14.4889 20M9.60651 18.6263C5.97465 17.2943 4.10205 13.2478 5.42394 9.58823C6.36371 6.98651 8.66504 5.28075 11.222 4.99059M11.222 4.99059L10.1747 6.74471M11.222 4.99059L9.51114 4"
      className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Sun = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.4"
        d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M12 22.96C11.45 22.96 11 22.55 11 22V21.92C11 21.37 11.45 20.92 12 20.92C12.55 20.92 13 21.37 13 21.92C13 22.47 12.55 22.96 12 22.96ZM19.14 20.14C18.88 20.14 18.63 20.04 18.43 19.85L18.3 19.72C17.91 19.33 17.91 18.7 18.3 18.31C18.69 17.92 19.32 17.92 19.71 18.31L19.84 18.44C20.23 18.83 20.23 19.46 19.84 19.85C19.65 20.04 19.4 20.14 19.14 20.14ZM4.86 20.14C4.6 20.14 4.35 20.04 4.15 19.85C3.76 19.46 3.76 18.83 4.15 18.44L4.28 18.31C4.67 17.92 5.3 17.92 5.69 18.31C6.08 18.7 6.08 19.33 5.69 19.72L5.56 19.85C5.37 20.04 5.11 20.14 4.86 20.14ZM22 13H21.92C21.37 13 20.92 12.55 20.92 12C20.92 11.45 21.37 11 21.92 11C22.47 11 22.96 11.45 22.96 12C22.96 12.55 22.55 13 22 13ZM2.08 13H2C1.45 13 1 12.55 1 12C1 11.45 1.45 11 2 11C2.55 11 3.04 11.45 3.04 12C3.04 12.55 2.63 13 2.08 13ZM19.01 5.99C18.75 5.99 18.5 5.89 18.3 5.7C17.91 5.31 17.91 4.68 18.3 4.29L18.43 4.16C18.82 3.77 19.45 3.77 19.84 4.16C20.23 4.55 20.23 5.18 19.84 5.57L19.71 5.7C19.52 5.89 19.27 5.99 19.01 5.99ZM4.99 5.99C4.73 5.99 4.48 5.89 4.28 5.7L4.15 5.56C3.76 5.17 3.76 4.54 4.15 4.15C4.54 3.76 5.17 3.76 5.56 4.15L5.69 4.28C6.08 4.67 6.08 5.3 5.69 5.69C5.5 5.89 5.24 5.99 4.99 5.99ZM12 3.04C11.45 3.04 11 2.63 11 2.08V2C11 1.45 11.45 1 12 1C12.55 1 13 1.45 13 2C13 2.55 12.55 3.04 12 3.04Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Moon = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M9.00068 19.0001C9.00068 19.8401 9.13068 20.6601 9.37068 21.4201C5.53068 20.0901 2.63068 16.5601 2.33068 12.4301C2.03068 8.04012 4.56068 3.94012 8.65068 2.22012C9.71068 1.78012 10.2507 2.10012 10.4807 2.33012C10.7007 2.55012 11.0107 3.08012 10.5707 4.09012C10.1207 5.13012 9.90068 6.23012 9.90068 7.37012C9.91068 9.41012 10.7107 11.3001 12.0107 12.7501C10.1807 14.2101 9.00068 16.4701 9.00068 19.0001Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.4"
        d="M21.21 17.72C19.23 20.41 16.09 21.99 12.74 21.99C12.58 21.99 12.42 21.98 12.26 21.97C11.26 21.93 10.29 21.74 9.37 21.42C9.13 20.66 9 19.84 9 19C9 16.47 10.18 14.21 12.01 12.75C13.48 14.4 15.59 15.47 17.92 15.57C18.55 15.6 19.18 15.55 19.8 15.44C20.92 15.24 21.37 15.66 21.53 15.93C21.7 16.2 21.88 16.79 21.21 17.72Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Search = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        opacity="0.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C4.5885 3 3 4.5885 3 12C3 19.4115 4.5885 21 12 21C19.4115 21 21 19.4115 21 12C21 4.5885 19.4115 3 12 3ZM11.5 7.75C9.42893 7.75 7.75 9.42893 7.75 11.5C7.75 13.5711 9.42893 15.25 11.5 15.25C13.5711 15.25 15.25 13.5711 15.25 11.5C15.25 9.42893 13.5711 7.75 11.5 7.75Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M3 12C3 4.5885 4.5885 3 12 3C19.4115 3 21 4.5885 21 12C21 19.4115 19.4115 21 12 21C4.5885 21 3 19.4115 3 12Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="1.5"
      />
      <path
        d="M14 14L16 16"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 11.5C15 13.433 13.433 15 11.5 15C9.567 15 8 13.433 8 11.5C8 9.567 9.567 8 11.5 8C13.433 8 15 9.567 15 11.5Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export const Write = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.186 2.09c.521.25 1.136.612 1.625 1.101.49.49.852 1.104 1.1 1.625.313.654.11 1.408-.401 1.92l-7.214 7.213c-.31.31-.688.541-1.105.675l-4.222 1.353a.75.75 0 0 1-.943-.944l1.353-4.221a2.75 2.75 0 0 1 .674-1.105l7.214-7.214c.512-.512 1.266-.714 1.92-.402zm.211 2.516a3.608 3.608 0 0 0-.828-.586l-6.994 6.994a1.002 1.002 0 0 0-.178.241L9.9 14.102l2.846-1.496c.09-.047.171-.107.242-.178l6.994-6.994a3.61 3.61 0 0 0-.586-.828zM4.999 5.5A.5.5 0 0 1 5.47 5l5.53.005a1 1 0 0 0 0-2L5.5 3A2.5 2.5 0 0 0 3 5.5v12.577c0 .76.082 1.185.319 1.627.224.419.558.754.977.978.442.236.866.318 1.627.318h12.154c.76 0 1.185-.082 1.627-.318.42-.224.754-.559.978-.978.236-.442.318-.866.318-1.627V13a1 1 0 1 0-2 0v5.077c0 .459-.021.571-.082.684a.364.364 0 0 1-.157.157c-.113.06-.225.082-.684.082H5.923c-.459 0-.57-.022-.684-.082a.363.363 0 0 1-.157-.157c-.06-.113-.082-.225-.082-.684V5.5z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </svg>
  </motion.div>
);

export const Typification = (props: IconProps) => (
  <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M20 14.25C21.2426 14.25 22.25 13.2426 22.25 12C22.25 10.7574 21.2426 9.75 20 9.75C18.7574 9.75 17.75 10.7574 17.75 12C17.75 13.2426 18.7574 14.25 20 14.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M20 6.25C21.2426 6.25 22.25 5.24264 22.25 4C22.25 2.75736 21.2426 1.75 20 1.75C18.7574 1.75 17.75 2.75736 17.75 4C17.75 5.24264 18.7574 6.25 20 6.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M20 22.25C21.2426 22.25 22.25 21.2426 22.25 20C22.25 18.7574 21.2426 17.75 20 17.75C18.7574 17.75 17.75 18.7574 17.75 20C17.75 21.2426 18.7574 22.25 20 22.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        d="M4 14.25C5.24264 14.25 6.25 13.2426 6.25 12C6.25 10.7574 5.24264 9.75 4 9.75C2.75736 9.75 1.75 10.7574 1.75 12C1.75 13.2426 2.75736 14.25 4 14.25Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
      <path
        opacity="0.4"
        d="M19 12.75C19.41 12.75 19.75 12.41 19.75 12C19.75 11.59 19.41 11.25 19 11.25H11.75V7C11.75 5.42 12.42 4.75 14 4.75H19C19.41 4.75 19.75 4.41 19.75 4C19.75 3.59 19.41 3.25 19 3.25H14C11.58 3.25 10.25 4.58 10.25 7V11.25H5C4.59 11.25 4.25 11.59 4.25 12C4.25 12.41 4.59 12.75 5 12.75H10.25V17C10.25 19.42 11.58 20.75 14 20.75H19C19.41 20.75 19.75 20.41 19.75 20C19.75 19.59 19.41 19.25 19 19.25H14C12.42 19.25 11.75 18.58 11.75 17V12.75H19Z"
        className="fill-[#4d5666] dark:fill-[#ebeef2]"
      />
    </g>
  </svg>
);

export const Proposal = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 9.3345 19.8412 6.93964 18 5.29168M8 16L16 8M17 15C17 16.1046 16.1046 17 15 17C13.8954 17 13 16.1046 13 15C13 13.8954 13.8954 13 15 13C16.1046 13 17 13.8954 17 15ZM11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </motion.div>
);

export const Bell = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      {...props}
      height="24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className="fill-[#4d5666] dark:fill-[#fcd53f]"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M494.382,302.749c-8.063-12.985-22.605-22.83-38.333-26.087l-78.643-54.472 c-2.533-33.106-27.337-39.278-32.727-40.229l-23.067-65.128c-21.733-61.413-82.3-104.301-147.289-104.301 c-7.585,0-15.235,0.574-22.754,1.716c-0.965-1.564-2.087-3.051-3.342-4.433c-3.954-4.317-9.038-7.376-14.701-8.851 C131.064,0.326,128.523,0,125.973,0c-3.436,0-6.819,0.582-10.05,1.735c-3.801,1.343-7.252,3.412-10.268,6.153 c-4.307,3.944-7.362,9.022-8.836,14.689c-0.962,3.727-1.199,7.547-0.702,11.378C33.151,71.068,4.218,151.052,28.801,220.505 l23.051,65.124c-3.591,4.134-18.979,24.538-0.12,51.868l-26.617,91.362c-19.556,27.978-18.653,57.283,2.246,72.931 c5.77,4.309,11.252,6.805,17.166,7.838C51.363,511.2,59.979,512,70.133,512c0.006,0,0.002,0,0.008,0 c48.626,0,127.147-17.527,210.037-46.879c101.099-35.784,185.95-81.479,211.083-113.63c4-4.55,6.693-9.938,8.473-16.949 C502.458,323.77,500.607,312.776,494.382,302.749z M120.73,22.244c0.644-0.423,1.345-0.769,2.1-1.04 c0.73-0.264,1.483-0.431,2.244-0.505C124.403,20.924,121.501,21.956,120.73,22.244z M243.749,362.233 c65.501-23.195,128.395-37.042,168.244-37.042c21.341,0,34.579,4.192,37.275,11.805c8.102,22.896-72.322,73.129-175.616,109.693 c-65.516,23.195-128.424,37.046-168.275,37.046c-21.332,0-34.561-4.189-37.256-11.794 C60.026,449.05,140.453,398.805,243.749,362.233z"></path>
          <path d="M272.716,377.097c0.173,0.691,0.349,1.304,0.6,1.964c6.625,18.742,24.464,31.335,44.391,31.335h0.002 c5.328,0,10.602-0.908,15.677-2.694c17.75-6.32,29.992-22.5,31.184-41.203c0.495-6.126-0.361-12.539-2.473-18.544l-0.448-1.27 l-1.326,0.233c-26.809,4.685-57.257,12.317-88.055,22.073l-1.3,0.412l0.208,1.343C271.532,373.067,272.049,375.187,272.716,377.097 z" />
        </g>
      </g>
    </svg>
  </motion.div>
);

export const Base = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="64px"
      height="64px"
      {...props}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g className="fill-[#4d5666] dark:fill-[#ebeef2]">
          <ellipse cx="14" cy="8" rx="11" ry="6"></ellipse>
          <path d="M14,24c-4.8,0-8.8-1.4-11-3.6V24c0,3.4,4.8,6,11,6c0.9,0,1.8-0.1,2.7-0.2C15.2,28.3,14.3,26.2,14,24C14,24,14,24,14,24z"></path>
          <path d="M3,12.4V16c0,3.4,4.8,6,11,6c0,0,0,0,0.1,0c0.2-2.4,1.4-4.6,3-6.2c-1,0.1-2,0.2-3.1,0.2C9.2,16,5.2,14.6,3,12.4z"></path>
        </g>
        <path
          className="fill-[#4d5666] dark:fill-[#ebeef2]"
          d="M24,15c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8S28.4,15,24,15z M27,24h-2v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2h-2 c-0.6,0-1-0.4-1-1s0.4-1,1-1h2v-2c0-0.6,0.4-1,1-1s1,0.4,1,1v2h2c0.6,0,1,0.4,1,1S27.6,24,27,24z"
        />
      </g>
    </svg>
  </motion.div>
);

export const Fullscreen = (props: IconProps) => (
  <motion.div whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="SVGRepo_iconCarrier">
        <g id="style=stroke">
          <g id="fullscreen">
            <path
              id="vector (Stroke)"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H8C8.41421 1.25 8.75 1.58579 8.75 2C8.75 2.41421 8.41421 2.75 8 2.75H4C3.30964 2.75 2.75 3.30964 2.75 4V8C2.75 8.41421 2.41421 8.75 2 8.75C1.58579 8.75 1.25 8.41421 1.25 8V4Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20 1.25C21.5188 1.25 22.75 2.48122 22.75 4L22.75 8C22.75 8.41421 22.4142 8.75 22 8.75C21.5858 8.75 21.25 8.41421 21.25 8L21.25 4C21.25 3.30964 20.6904 2.75 20 2.75L16 2.75C15.5858 2.75 15.25 2.41421 15.25 2C15.25 1.58579 15.5858 1.25 16 1.25L20 1.25Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.75 20C22.75 21.5188 21.5188 22.75 20 22.75L16 22.75C15.5858 22.75 15.25 22.4142 15.25 22C15.25 21.5858 15.5858 21.25 16 21.25L20 21.25C20.6904 21.25 21.25 20.6904 21.25 20L21.25 16C21.25 15.5858 21.5858 15.25 22 15.25C22.4142 15.25 22.75 15.5858 22.75 16L22.75 20Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
            <path
              id="vector (Stroke)_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M1.25 20C1.25 21.5188 2.48122 22.75 4 22.75L8 22.75C8.41421 22.75 8.75 22.4142 8.75 22C8.75 21.5858 8.41421 21.25 8 21.25L4 21.25C3.30964 21.25 2.75 20.6904 2.75 20L2.75 16C2.75 15.5858 2.41421 15.25 2 15.25C1.58579 15.25 1.25 15.5858 1.25 16L1.25 20Z"
              className="fill-[#4d5666] dark:fill-[#ebeef2]"
            />
          </g>
        </g>
      </g>
    </svg>
  </motion.div>
);

export const Expand = (props: IconProps) => (
  <motion.div className="cursor-pointer" whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <polyline
          data-name="Right"
          fill="none"
          id="Right-2"
          points="3 17.3 3 21 6.7 21"
          className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          fill="none"
          className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          x1="10"
          x2="3.8"
          y1="14"
          y2="20.2"
        />
        <line
          fill="none"
          className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          x1="14"
          x2="20.2"
          y1="10"
          y2="3.8"
        />
        <polyline
          data-name="Right"
          fill="none"
          id="Right-3"
          points="21 6.7 21 3 17.3 3"
          className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  </motion.div>
);

export const Shrink = (props: IconProps) => (
  <motion.div className="cursor-pointer" whileTap={{ scale: 0.7 }}>
    <svg
      version="1.1"
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      width="64px"
      height="64px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        id="Vector"
        d="M5 14H10V19M19 10H14V5"
        className="stroke-[#4d5666] dark:stroke-[#ebeef2]"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </motion.div>
);

export const Logo = (props: IconProps) => (
  <svg
    width="280"
    height="19"
    viewBox="0 0 280 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.6987 10.8466H34.8799L34.0781 14.737H21.9278L22.6987 10.8466ZM29.144 6.02361L21.0643 18.4408H15.6367L27.0778 1.01409H34.294L38.7656 18.4408H33.338L30.1925 6.02361V4.53141H29.7607L29.144 6.02361Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M39.0555 1.01409H58.0828L57.281 4.90446H50.1882L47.4436 18.4408H42.5711L45.3157 4.90446H38.2537L39.0555 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M76.4482 1.01409L72.9018 18.4408H68.0602L70.8048 4.90446H65.4697L64.1128 11.6726C63.8661 12.7918 63.5064 13.7688 63.0335 14.6037C62.5606 15.4209 62.0261 16.0782 61.4299 16.5756C60.8542 17.0552 60.1655 17.4371 59.3637 17.7214C58.5619 18.0056 57.7704 18.201 56.9892 18.3076C56.2285 18.3964 55.3753 18.4408 54.4296 18.4408H53.3811L54.1829 14.4972H55.2314C55.7659 14.4972 56.2079 14.4705 56.5574 14.4172C56.9275 14.3462 57.3078 14.2129 57.6984 14.0175C58.1096 13.8221 58.4488 13.5112 58.7161 13.0849C58.9834 12.6408 59.189 12.0723 59.3329 11.3795L61.4299 1.01409H76.4482Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M81.7857 10.8466H93.9669L93.1651 14.737H81.0147L81.7857 10.8466ZM88.2309 6.02361L80.1512 18.4408H74.7237L86.1647 1.01409H93.3809L97.8525 18.4408H92.425L89.2794 6.02361V4.53141H88.8477L88.2309 6.02361Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M103.255 1.01409H108.096L106.77 7.51581H116.238L117.564 1.01409H122.436L118.859 18.4408H113.987L115.436 11.4328H105.969L104.519 18.4408H99.6775L103.255 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M124.688 1.01409H143.715L142.913 4.90446H135.82L133.076 18.4408H128.203L130.948 4.90446H123.886L124.688 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M151.38 1.01409H155.081L153.477 8.90142L163.438 1.01409H168.095L158.504 8.95471L164.518 18.4408H159.491L155.081 11.1397L152.676 12.8984L151.535 18.4408H147.803L151.38 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M173.603 5.25087C172.493 6.31672 171.742 7.8178 171.352 9.7541C170.961 11.6726 171.095 13.1648 171.753 14.2307C172.431 15.2965 173.551 15.8295 175.114 15.8295C176.697 15.8295 178.033 15.3054 179.123 14.2573C180.233 13.1915 180.984 11.6904 181.374 9.7541C181.765 7.8178 181.631 6.31672 180.973 5.25087C180.315 4.16725 179.195 3.62544 177.612 3.62544C176.049 3.62544 174.713 4.16725 173.603 5.25087ZM167.589 9.7541C168.186 6.85853 169.44 4.59359 171.352 2.95928C173.264 1.32497 175.566 0.507812 178.259 0.507812C180.768 0.507812 182.68 1.34273 183.995 3.01257C185.332 4.66465 185.712 6.91182 185.136 9.7541C184.54 12.6674 183.286 14.9413 181.374 16.5756C179.483 18.1921 177.19 19.0004 174.497 19.0004C171.804 19.0004 169.83 18.1921 168.576 16.5756C167.322 14.9413 166.993 12.6674 167.589 9.7541Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M189.025 1.01409H192.726L191.307 8.04874H199.603L201.052 1.01409H204.783L201.237 18.4408H197.475L198.986 11.0864H190.69L189.179 18.4408H185.448L189.025 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M222.846 6.60983L219.084 7.2227C218.961 6.06802 218.57 5.17981 217.912 4.55806C217.254 3.93631 216.35 3.62544 215.198 3.62544C213.595 3.62544 212.228 4.16725 211.097 5.25087C209.987 6.31672 209.226 7.83556 208.815 9.8074C208.424 11.7259 208.568 13.2092 209.247 14.2573C209.946 15.3054 211.107 15.8295 212.731 15.8295C213.924 15.8295 215.003 15.4831 215.969 14.7903C216.956 14.0975 217.696 13.1293 218.19 11.8858L221.705 12.5253C220.842 14.586 219.547 16.1848 217.82 17.3217C216.093 18.4408 214.181 19.0004 212.084 19.0004C209.37 19.0004 207.376 18.1921 206.101 16.5756C204.826 14.959 204.487 12.703 205.083 9.8074C205.68 6.85853 206.944 4.57583 208.877 2.95928C210.809 1.32497 213.132 0.507812 215.846 0.507812C217.758 0.507812 219.331 1.04074 220.564 2.10659C221.818 3.15469 222.579 4.65576 222.846 6.60983Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M228.192 11.1131H237.783L237.166 14.1241H227.575L228.192 11.1131ZM233.558 5.27751L225.818 18.4408H221.778L232.14 1.01409H237.814L241.114 18.4408H237.105L234.637 5.27751L234.607 3.78532H234.206L233.558 5.27751Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M262.153 1.01409L258.607 18.4408H254.875L257.836 4.02513H252.84L251.298 11.6726C251.072 12.7918 250.743 13.7688 250.311 14.6037C249.9 15.4209 249.437 16.0782 248.923 16.5756C248.43 17.0552 247.844 17.4371 247.166 17.7214C246.487 18.0056 245.829 18.201 245.192 18.3076C244.555 18.3964 243.845 18.4408 243.064 18.4408H242.386L243.002 15.3765H243.681C244.174 15.3765 244.585 15.341 244.914 15.2699C245.243 15.1811 245.593 15.0123 245.963 14.7636C246.353 14.5149 246.682 14.1152 246.95 13.5645C247.217 12.9961 247.443 12.2677 247.628 11.3795L249.756 1.01409H262.153Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path
      d="M264.273 1.01409H280L279.383 4.02513H273.401L270.44 18.4408H266.709L269.638 4.02513H263.656L264.273 1.01409Z"
      className="fill-[#4d5666] dark:fill-[#ebeef2]"
    />
    <path d="M17.8509 0H23.0657L12.4355 17.3942H7.2207L17.8509 0Z" fill="#0070B9" />
    <path d="M10.6302 0H15.845L5.21482 17.3942H0L10.6302 0Z" fill="#0070B9" />
  </svg>
);

export const LogoMobile = (props: IconProps) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9734 0.553467H9.37457L0 18.4249H4.59885L13.9734 0.553467ZM20.3411 0.553711H15.7422L6.36768 18.4251H10.9665L20.3411 0.553711Z"
      className="fill-[#0070B9]"
    />
  </svg>
);
