import { EnumUserType } from '__generated__/types';
import { memo } from 'react';
import { useUserByTypeCount } from './__generated__/UserByTypeCountQuery';
import TypificationFilters from 'shared/components/filters/typificationFilters';

const Typification = memo(() => {
  const { data: clientsCount } = useUserByTypeCount();

  return (
    <div className="mt-4 grid grid-cols-2 gap-2">
      <TypificationFilters
        className={'col-span-2'}
        type="ВСЕ КЛИЕНТЫ"
        allCount={clientsCount?.userByTypeCount?.allCount || 0}
      />
      {Object.entries(EnumUserType).map(([, type], index) => (
        <TypificationFilters
          clientsTypeCount={clientsCount?.userByTypeCount}
          key={index}
          type={type}
        />
      ))}
    </div>
  );
});

export default Typification;
