import { useFullscreen } from 'app/context/fullscreenContext';
import { useUserRole } from 'app/context/userContext';
import SideMenu from 'shared/components/sideMenu';
import Header from 'widgets/header';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { userRole, userOrg } = useUserRole();
  const { fullscreen } = useFullscreen();
  const isOpgt = userOrg === 'AC_OPGT';

  return (
    <>
      <Header />
      <main className="relative flex h-[calc(100vh-110px)] w-full gap-x-2 px-[6%] lg:px-[1%] xl:gap-x-4">
        {userRole && !fullscreen && !isOpgt && (
          <div className="pageBlock min-w-[250px]">
            <SideMenu />
          </div>
        )}
        <div className="flex w-full flex-row gap-x-2 xl:gap-x-4">{children}</div>
      </main>
    </>
  );
}
