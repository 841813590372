import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface FilterContextProps {
  filter: string;
  setFilter: (filter: string) => void;
  hostFilter: string;
  setHostFilter: (filter: string) => void;
  vpsFilter: string;
  setVpsFilter: (filter: string) => void;
  versionFilter: string;
  setVersionFilter: (filter: string) => void;
  chatsFilter: string;
  setChatsFilter: (filter: string) => void;
  authFilter: string;
  setAuthFilter: (filter: string) => void;
  typificationFilter: string;
  setTypificationFilter: (filter: string) => void;
  timezoneFilter: string;
  setTimezoneFilter: (filter: string) => void;
}
interface FilterProviderProps {
  children: ReactNode;
}

const FilterContext = createContext({} as FilterContextProps);

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const [filter, setFilter] = useState(() => localStorage.getItem('filter') ?? '');
  const [hostFilter, setHostFilter] = useState(() => localStorage.getItem('hostFilter') ?? '');
  const [vpsFilter, setVpsFilter] = useState(() => localStorage.getItem('vpsFilter') ?? '');
  const [versionFilter, setVersionFilter] = useState(
    () => localStorage.getItem('versionFilter') ?? ''
  );
  const [chatsFilter, setChatsFilter] = useState(() => localStorage.getItem('chatsFilter') ?? '');
  const [authFilter, setAuthFilter] = useState(() => localStorage.getItem('authFilter') ?? '');
  const [typificationFilter, setTypificationFilter] = useState(
    () => localStorage.getItem('typificationFilter') ?? ''
  );
  const [timezoneFilter, setTimezoneFilter] = useState(
    () => localStorage.getItem('timezoneFilter') ?? ''
  );

  useEffect(() => {
    localStorage.setItem('filter', filter);
  }, [filter]);

  useEffect(() => {
    localStorage.setItem('hostFilter', hostFilter);
  }, [hostFilter]);

  useEffect(() => {
    localStorage.setItem('vpsFilter', vpsFilter);
  }, [vpsFilter]);

  useEffect(() => {
    localStorage.setItem('versionFilter', versionFilter);
  }, [versionFilter]);

  useEffect(() => {
    localStorage.setItem('chatsFilter', chatsFilter);
  }, [chatsFilter]);

  useEffect(() => {
    localStorage.setItem('authFilter', authFilter);
  }, [authFilter]);

  useEffect(() => {
    localStorage.setItem('typificationFilter', typificationFilter);
  }, [typificationFilter]);

  useEffect(() => {
    localStorage.setItem('timezoneFilter', timezoneFilter);
  }, [timezoneFilter]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        setFilter,
        hostFilter,
        setHostFilter,
        vpsFilter,
        setVpsFilter,
        versionFilter,
        setVersionFilter,
        chatsFilter,
        setChatsFilter,
        authFilter,
        setAuthFilter,
        typificationFilter,
        setTypificationFilter,
        timezoneFilter,
        setTimezoneFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);
