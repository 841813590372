export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `ID` scalar type represents a unique MongoDB identifier in collection. MongoDB by default use 12-byte ObjectId value (https://docs.mongodb.com/manual/reference/bson-types/#objectid). But MongoDB also may accepts string or integer as correct values for _id field. */
  MongoID: { input: string; output: string; }
  /** The string representation of JavaScript regexp. You may provide it with flags "/^abc.*\/i" or without flags like "^abc.*". More info about RegExp characters and flags: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  RegExpAsString: { input: string; output: string; }
};

export type BitrixDeal = {
  __typename?: 'BitrixDeal';
  ADDITIONAL_INFO?: Maybe<Scalars['String']['output']>;
  ASSIGNED_BY_ID?: Maybe<Scalars['String']['output']>;
  BEGINDATE?: Maybe<Scalars['String']['output']>;
  CATEGORY_ID?: Maybe<Scalars['String']['output']>;
  CLOSED?: Maybe<Scalars['String']['output']>;
  CLOSEDATE?: Maybe<Scalars['String']['output']>;
  COMMENTS?: Maybe<Scalars['String']['output']>;
  COMPANY_ID?: Maybe<Scalars['String']['output']>;
  CONTACT_ID?: Maybe<Scalars['String']['output']>;
  CREATED_BY_ID?: Maybe<Scalars['String']['output']>;
  CURRENCY_ID?: Maybe<Scalars['String']['output']>;
  DATE_CREATE?: Maybe<Scalars['String']['output']>;
  DATE_MODIFY?: Maybe<Scalars['String']['output']>;
  ID?: Maybe<Scalars['String']['output']>;
  IS_MANUAL_OPPORTUNITY?: Maybe<Scalars['String']['output']>;
  IS_NEW?: Maybe<Scalars['String']['output']>;
  IS_RECURRING?: Maybe<Scalars['String']['output']>;
  IS_REPEATED_APPROACH?: Maybe<Scalars['String']['output']>;
  IS_RETURN_CUSTOMER?: Maybe<Scalars['String']['output']>;
  LAST_ACTIVITY_BY?: Maybe<Scalars['String']['output']>;
  LAST_ACTIVITY_TIME?: Maybe<Scalars['String']['output']>;
  LEAD_ID?: Maybe<Scalars['String']['output']>;
  LOCATION_ID?: Maybe<Scalars['String']['output']>;
  MODIFY_BY_ID?: Maybe<Scalars['String']['output']>;
  MOVED_BY_ID?: Maybe<Scalars['String']['output']>;
  MOVED_TIME?: Maybe<Scalars['String']['output']>;
  OPENED?: Maybe<Scalars['String']['output']>;
  OPPORTUNITY?: Maybe<Scalars['String']['output']>;
  ORIGINATOR_ID?: Maybe<Scalars['String']['output']>;
  ORIGIN_ID?: Maybe<Scalars['String']['output']>;
  PROBABILITY?: Maybe<Scalars['String']['output']>;
  QUOTE_ID?: Maybe<Scalars['String']['output']>;
  SOURCE_DESCRIPTION?: Maybe<Scalars['String']['output']>;
  SOURCE_ID?: Maybe<Scalars['String']['output']>;
  STAGE_ID?: Maybe<Scalars['String']['output']>;
  STAGE_SEMANTIC_ID?: Maybe<Scalars['String']['output']>;
  TAX_VALUE?: Maybe<Scalars['String']['output']>;
  TITLE?: Maybe<Scalars['String']['output']>;
  TYPE_ID?: Maybe<Scalars['String']['output']>;
  UF_CRM_1628047142?: Maybe<Scalars['String']['output']>;
  UF_CRM_1628399802?: Maybe<Scalars['String']['output']>;
  UF_CRM_1719322478?: Maybe<Scalars['String']['output']>;
  UF_CRM_1586352504914?: Maybe<Scalars['String']['output']>;
  UF_CRM_1586352842259?: Maybe<Scalars['String']['output']>;
  UF_CRM_1588928159396?: Maybe<Scalars['String']['output']>;
  UF_CRM_1599037649974?: Maybe<Scalars['String']['output']>;
  UF_CRM_1603804285531?: Maybe<Scalars['String']['output']>;
  UF_CRM_1603804294187?: Maybe<Scalars['String']['output']>;
  UF_CRM_1603804302701?: Maybe<Scalars['String']['output']>;
  UF_CRM_1603804311656?: Maybe<Scalars['String']['output']>;
  UF_CRM_1618402250988?: Maybe<Scalars['String']['output']>;
  UF_CRM_1647003988648?: Maybe<Scalars['String']['output']>;
  UF_CRM_1649151341324?: Maybe<Scalars['String']['output']>;
  UF_CRM_1674899110969?: Maybe<Scalars['String']['output']>;
  UF_CRM_1674899143410?: Maybe<Scalars['String']['output']>;
  UF_CRM_1674899682791?: Maybe<Scalars['String']['output']>;
  UF_CRM_1674900305256?: Maybe<Scalars['String']['output']>;
  UF_CRM_1680188859667?: Maybe<Scalars['String']['output']>;
  UF_CRM_1681202591734?: Maybe<Scalars['String']['output']>;
  UF_CRM_1688129559556?: Maybe<Scalars['String']['output']>;
  UF_CRM_1688129707450?: Maybe<Scalars['String']['output']>;
  UF_CRM_1688130314072?: Maybe<Scalars['String']['output']>;
  UF_CRM_1694972600573?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  UF_CRM_1704790470677?: Maybe<Scalars['String']['output']>;
  UF_CRM_1719994496823?: Maybe<Scalars['String']['output']>;
  UF_CRM_CUST_LTIME?: Maybe<Scalars['String']['output']>;
  UTM_CAMPAIGN?: Maybe<Scalars['String']['output']>;
  UTM_CONTENT?: Maybe<Scalars['String']['output']>;
  UTM_MEDIUM?: Maybe<Scalars['String']['output']>;
  UTM_SOURCE?: Maybe<Scalars['String']['output']>;
  UTM_TERM?: Maybe<Scalars['String']['output']>;
  _id: Scalars['MongoID']['output'];
};

export type BroadcastAccounUserWa = {
  __typename?: 'BroadcastAccounUserWa';
  _id: Scalars['MongoID']['output'];
  accountHost?: Maybe<Scalars['String']['output']>;
  accountPhone?: Maybe<Scalars['String']['output']>;
  broadcastId?: Maybe<Scalars['String']['output']>;
  clientPhone?: Maybe<Scalars['String']['output']>;
  companyInn?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  deals: Array<UserBitrixDeal>;
  t?: Maybe<Scalars['Float']['output']>;
  timeZone?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EnumBroadcastAccounUserWaType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};


export type BroadcastAccounUserWaDealsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyUserBitrixDealInput>;
};


export type BroadcastAccounUserWaUserArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneUserInput>;
};

export type BroadcastChats = {
  __typename?: 'BroadcastChats';
  _id: Scalars['MongoID']['output'];
  chat?: Maybe<WaChat>;
  clientPhone?: Maybe<Scalars['String']['output']>;
  deals: Array<UserBitrixDeal>;
  host?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<Array<Maybe<WaMessage>>>;
};


export type BroadcastChatsDealsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyUserBitrixDealInput>;
};

export type BroadcastWa = {
  __typename?: 'BroadcastWa';
  _id: Scalars['MongoID']['output'];
  accounts?: Maybe<Array<Maybe<ClientStatus>>>;
  base?: Maybe<Scalars['String']['output']>;
  checkState?: Maybe<CheckState>;
  client_phones?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  clients: Array<BroadcastAccounUserWa>;
  clientsCountBlock?: Maybe<Scalars['Int']['output']>;
  clientsCountLead?: Maybe<Scalars['Int']['output']>;
  clientsCountNeutral?: Maybe<Scalars['Int']['output']>;
  clientsCountNew?: Maybe<Scalars['Int']['output']>;
  clientsCountQueue?: Maybe<Scalars['Int']['output']>;
  clientsCountReject?: Maybe<Scalars['Int']['output']>;
  clientsCountReplied?: Maybe<Scalars['Int']['output']>;
  clientsCountSent?: Maybe<Scalars['Int']['output']>;
  clientsCountSentView?: Maybe<Scalars['Int']['output']>;
  countSend?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  latestMessageTime?: Maybe<Scalars['Date']['output']>;
  limit?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sender_accs?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  status?: Maybe<EnumBroadcastWaStatus>;
  text_greeting?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  text_neutral?: Maybe<Scalars['String']['output']>;
  text_reject?: Maybe<Scalars['String']['output']>;
  text_success?: Maybe<Scalars['String']['output']>;
  text_utp?: Maybe<Scalars['String']['output']>;
  text_who_are_you?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EnumBroadcastWaType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};


export type BroadcastWaClientsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyBroadcastAccounUserWaInput>;
};

/** List of items with pagination. */
export type BroadcastWaPagination = {
  __typename?: 'BroadcastWaPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<BroadcastWa>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type CheckState = {
  __typename?: 'CheckState';
  _id: Scalars['String']['output'];
  checkChats?: Maybe<CheckStateCheckChats>;
  checkPhones?: Maybe<CheckStateCheckPhones>;
  isRunning?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckStateCheckChats = {
  __typename?: 'CheckStateCheckChats';
  _id?: Maybe<Scalars['MongoID']['output']>;
  countAll?: Maybe<Scalars['Float']['output']>;
  countCheck?: Maybe<Scalars['Float']['output']>;
  isCheckerWaChats?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckStateCheckPhones = {
  __typename?: 'CheckStateCheckPhones';
  _id?: Maybe<Scalars['MongoID']['output']>;
  countAll?: Maybe<Scalars['Float']['output']>;
  countCheck?: Maybe<Scalars['Float']['output']>;
  isCheckerWaPhones?: Maybe<Scalars['Boolean']['output']>;
};

export type ClientStatus = {
  __typename?: 'ClientStatus';
  _id: Scalars['MongoID']['output'];
  linkCode?: Maybe<Scalars['String']['output']>;
  nameVitrualMachine?: Maybe<Scalars['String']['output']>;
  needPhone?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Scalars['String']['output']>;
  percent?: Maybe<Scalars['Float']['output']>;
  profileInfo?: Maybe<ProfileInfo>;
  statusWbot?: Maybe<Scalars['String']['output']>;
  versionCode?: Maybe<Scalars['String']['output']>;
  waClient?: Maybe<ClientStatusWaClient>;
};

export type ClientStatusWaClient = {
  __typename?: 'ClientStatusWaClient';
  _id?: Maybe<Scalars['MongoID']['output']>;
  adbHost?: Maybe<Scalars['String']['output']>;
  allCount?: Maybe<Scalars['Float']['output']>;
  autoSeenChats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  banned_date?: Maybe<Scalars['Date']['output']>;
  battery?: Maybe<Scalars['String']['output']>;
  chatsCount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  farewellMessage?: Maybe<Scalars['String']['output']>;
  greetingMessage?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  host?: Maybe<Scalars['String']['output']>;
  messagesCount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  needConnectQrCode?: Maybe<Scalars['Boolean']['output']>;
  novoreg_date?: Maybe<Scalars['Date']['output']>;
  openReadChats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phone?: Maybe<Scalars['Float']['output']>;
  plugged?: Maybe<Scalars['Boolean']['output']>;
  profilePicUrl?: Maybe<Scalars['String']['output']>;
  progrev_date?: Maybe<Scalars['Date']['output']>;
  progrev_good?: Maybe<Scalars['Boolean']['output']>;
  progrev_number?: Maybe<Scalars['Float']['output']>;
  qrcode?: Maybe<Scalars['String']['output']>;
  repliedCount?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
  session?: Maybe<Scalars['String']['output']>;
  sessionCount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<EnumClientStatusWaClientStatus>;
  unreadCount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  wmmail_good?: Maybe<Scalars['Boolean']['output']>;
  wmmail_number?: Maybe<Scalars['Float']['output']>;
  zennoPosterHost?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename?: 'Company';
  _id: Scalars['MongoID']['output'];
  actuality_date?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<CompanyAddress>;
  bitrix_deal_id?: Maybe<Scalars['String']['output']>;
  branch_count?: Maybe<Scalars['Float']['output']>;
  branch_type?: Maybe<Scalars['String']['output']>;
  broadcast_name?: Maybe<Scalars['String']['output']>;
  broadcast_status?: Maybe<EnumCompanyBroadcast_Status>;
  capital?: Maybe<Scalars['Float']['output']>;
  company_inn?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  dadata_info?: Maybe<DaData>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  employee_count?: Maybe<Scalars['Float']['output']>;
  fedresurs?: Maybe<FedresursPagination>;
  finance?: Maybe<CompanyFinance>;
  full?: Maybe<Scalars['String']['output']>;
  hid?: Maybe<Scalars['String']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  kpp?: Maybe<Scalars['String']['output']>;
  liquidation_date?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ogrn?: Maybe<Scalars['String']['output']>;
  ogrn_date?: Maybe<Scalars['Float']['output']>;
  okato?: Maybe<Scalars['String']['output']>;
  okfs?: Maybe<Scalars['String']['output']>;
  okogu?: Maybe<Scalars['String']['output']>;
  okpo?: Maybe<Scalars['String']['output']>;
  oktmo?: Maybe<Scalars['String']['output']>;
  okved?: Maybe<Scalars['String']['output']>;
  okved_type?: Maybe<Scalars['String']['output']>;
  okveds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phones?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  registration_date?: Maybe<Scalars['Float']['output']>;
  sheff_fio?: Maybe<Scalars['String']['output']>;
  short?: Maybe<Scalars['String']['output']>;
  state_status?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};


export type CompanyFedresursArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyFedresursInput>;
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  beltway_hit?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_iso_code?: Maybe<Scalars['String']['output']>;
  federal_district?: Maybe<Scalars['String']['output']>;
  geo_lat?: Maybe<Scalars['String']['output']>;
  geo_lon?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  region_iso_code?: Maybe<Scalars['String']['output']>;
  region_with_type?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type CompanyFinance = {
  __typename?: 'CompanyFinance';
  debt?: Maybe<Scalars['Float']['output']>;
  expense?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
  penalty?: Maybe<Scalars['Float']['output']>;
  tax_system?: Maybe<Scalars['Float']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type CreateOneBroadcastAccounUserWaInput = {
  accountHost?: InputMaybe<Scalars['String']['input']>;
  accountPhone?: InputMaybe<Scalars['String']['input']>;
  broadcastId?: InputMaybe<Scalars['String']['input']>;
  clientPhone?: InputMaybe<Scalars['String']['input']>;
  companyInn?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  t?: InputMaybe<Scalars['Float']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastAccounUserWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateOneBroadcastAccounUserWaPayload = {
  __typename?: 'CreateOneBroadcastAccounUserWaPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Created document */
  record?: Maybe<BroadcastAccounUserWa>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneBroadcastWaInput = {
  base?: InputMaybe<Scalars['String']['input']>;
  client_phones?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  countSend?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  latestMessageTime?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sender_accs?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  status?: InputMaybe<EnumBroadcastWaStatus>;
  text_greeting?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_neutral?: InputMaybe<Scalars['String']['input']>;
  text_reject?: InputMaybe<Scalars['String']['input']>;
  text_success?: InputMaybe<Scalars['String']['input']>;
  text_utp?: InputMaybe<Scalars['String']['input']>;
  text_who_are_you?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type CreateOneBroadcastWaPayload = {
  __typename?: 'CreateOneBroadcastWaPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Created document */
  record?: Maybe<BroadcastWa>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneNodeInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOneNodePayload = {
  __typename?: 'CreateOneNodePayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Created document */
  record?: Maybe<Node>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneOfferInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  kp_url?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wheelbase?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOneOfferPayload = {
  __typename?: 'CreateOneOfferPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  query?: Maybe<Query>;
  /** Created document */
  record?: Maybe<Offer>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type CreateOneUserInput = {
  banned?: InputMaybe<Scalars['Boolean']['input']>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  broadcast_negative?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast_wa?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isBusinessWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  isMAII?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2ProjectId?: InputMaybe<Scalars['String']['input']>;
  isMAIIv2ProjectName?: InputMaybe<Scalars['String']['input']>;
  isWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nameWhatsApp?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tags_eye?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tlgrm_id?: InputMaybe<Scalars['Float']['input']>;
  tlgrm_premium?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<EnumUserType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificated_eye?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateOneUserPayload = {
  __typename?: 'CreateOneUserPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Created document */
  record?: Maybe<User>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type DaData = {
  __typename?: 'DaData';
  _id: Scalars['MongoID']['output'];
  data?: Maybe<DaDataData>;
  unrestricted_value?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DaDataData = {
  __typename?: 'DaDataData';
  _id?: Maybe<Scalars['MongoID']['output']>;
  address?: Maybe<DaDataDataAddress>;
  emails?: Maybe<Array<Maybe<DaDataDataEmails>>>;
  employee_count?: Maybe<Scalars['Float']['output']>;
  finance?: Maybe<DaDataDataFinance>;
  inn?: Maybe<Scalars['String']['output']>;
  kpp?: Maybe<Scalars['String']['output']>;
  kpp_largest?: Maybe<Scalars['String']['output']>;
  name?: Maybe<DaDataDataName>;
  ogrn?: Maybe<Scalars['String']['output']>;
  ogrn_date?: Maybe<Scalars['Date']['output']>;
  okato?: Maybe<Scalars['String']['output']>;
  okfs?: Maybe<Scalars['String']['output']>;
  okogu?: Maybe<Scalars['String']['output']>;
  okpo?: Maybe<Scalars['String']['output']>;
  oktmo?: Maybe<Scalars['String']['output']>;
  okved?: Maybe<Scalars['String']['output']>;
  okved_type?: Maybe<Scalars['String']['output']>;
  okveds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DaDataDataAddress = {
  __typename?: 'DaDataDataAddress';
  _id?: Maybe<Scalars['MongoID']['output']>;
  data?: Maybe<DaDataDataAddressData>;
  unrestricted_value?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DaDataDataAddressData = {
  __typename?: 'DaDataDataAddressData';
  area?: Maybe<Scalars['String']['output']>;
  area_fias_id?: Maybe<Scalars['String']['output']>;
  area_kladr_id?: Maybe<Scalars['String']['output']>;
  area_type?: Maybe<Scalars['String']['output']>;
  area_type_full?: Maybe<Scalars['String']['output']>;
  area_with_type?: Maybe<Scalars['String']['output']>;
  beltway_distance?: Maybe<Scalars['String']['output']>;
  beltway_hit?: Maybe<Scalars['String']['output']>;
  block?: Maybe<Scalars['String']['output']>;
  block_type?: Maybe<Scalars['String']['output']>;
  block_type_full?: Maybe<Scalars['String']['output']>;
  capital_marker?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  city_area?: Maybe<Scalars['String']['output']>;
  city_district?: Maybe<Scalars['String']['output']>;
  city_district_fias_id?: Maybe<Scalars['String']['output']>;
  city_district_kladr_id?: Maybe<Scalars['String']['output']>;
  city_district_type?: Maybe<Scalars['String']['output']>;
  city_district_type_full?: Maybe<Scalars['String']['output']>;
  city_district_with_type?: Maybe<Scalars['String']['output']>;
  city_fias_id?: Maybe<Scalars['String']['output']>;
  city_kladr_id?: Maybe<Scalars['String']['output']>;
  city_type?: Maybe<Scalars['String']['output']>;
  city_type_full?: Maybe<Scalars['String']['output']>;
  city_with_type?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_iso_code?: Maybe<Scalars['String']['output']>;
  divisions?: Maybe<Scalars['String']['output']>;
  entrance?: Maybe<Scalars['String']['output']>;
  federal_district?: Maybe<Scalars['String']['output']>;
  fias_actuality_state?: Maybe<Scalars['String']['output']>;
  fias_code?: Maybe<Scalars['String']['output']>;
  fias_id?: Maybe<Scalars['String']['output']>;
  fias_level?: Maybe<Scalars['String']['output']>;
  flat?: Maybe<Scalars['String']['output']>;
  flat_area?: Maybe<Scalars['String']['output']>;
  flat_cadnum?: Maybe<Scalars['String']['output']>;
  flat_fias_id?: Maybe<Scalars['String']['output']>;
  flat_price?: Maybe<Scalars['String']['output']>;
  flat_type?: Maybe<Scalars['String']['output']>;
  flat_type_full?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  geo_lat?: Maybe<Scalars['String']['output']>;
  geo_lon?: Maybe<Scalars['String']['output']>;
  geoname_id?: Maybe<Scalars['String']['output']>;
  history_values?: Maybe<Scalars['String']['output']>;
  house?: Maybe<Scalars['String']['output']>;
  house_cadnum?: Maybe<Scalars['String']['output']>;
  house_fias_id?: Maybe<Scalars['String']['output']>;
  house_kladr_id?: Maybe<Scalars['String']['output']>;
  house_type?: Maybe<Scalars['String']['output']>;
  house_type_full?: Maybe<Scalars['String']['output']>;
  kladr_id?: Maybe<Scalars['String']['output']>;
  metro?: Maybe<Scalars['String']['output']>;
  okato?: Maybe<Scalars['String']['output']>;
  oktmo?: Maybe<Scalars['String']['output']>;
  postal_box?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  qc?: Maybe<Scalars['String']['output']>;
  qc_complete?: Maybe<Scalars['String']['output']>;
  qc_geo?: Maybe<Scalars['String']['output']>;
  qc_house?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  region_fias_id?: Maybe<Scalars['String']['output']>;
  region_iso_code?: Maybe<Scalars['String']['output']>;
  region_kladr_id?: Maybe<Scalars['String']['output']>;
  region_type?: Maybe<Scalars['String']['output']>;
  region_type_full?: Maybe<Scalars['String']['output']>;
  region_with_type?: Maybe<Scalars['String']['output']>;
  room?: Maybe<Scalars['String']['output']>;
  room_cadnum?: Maybe<Scalars['String']['output']>;
  room_fias_id?: Maybe<Scalars['String']['output']>;
  room_type?: Maybe<Scalars['String']['output']>;
  room_type_full?: Maybe<Scalars['String']['output']>;
  settlement?: Maybe<Scalars['String']['output']>;
  settlement_fias_id?: Maybe<Scalars['String']['output']>;
  settlement_kladr_id?: Maybe<Scalars['String']['output']>;
  settlement_type?: Maybe<Scalars['String']['output']>;
  settlement_type_full?: Maybe<Scalars['String']['output']>;
  settlement_with_type?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  square_meter_price?: Maybe<Scalars['String']['output']>;
  stead?: Maybe<Scalars['String']['output']>;
  stead_cadnum?: Maybe<Scalars['String']['output']>;
  stead_fias_id?: Maybe<Scalars['String']['output']>;
  stead_type?: Maybe<Scalars['String']['output']>;
  stead_type_full?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_fias_id?: Maybe<Scalars['String']['output']>;
  street_kladr_id?: Maybe<Scalars['String']['output']>;
  street_type?: Maybe<Scalars['String']['output']>;
  street_type_full?: Maybe<Scalars['String']['output']>;
  street_with_type?: Maybe<Scalars['String']['output']>;
  tax_office?: Maybe<Scalars['String']['output']>;
  tax_office_legal?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  unparsed_parts?: Maybe<Scalars['String']['output']>;
};

export type DaDataDataEmails = {
  __typename?: 'DaDataDataEmails';
  _id?: Maybe<Scalars['MongoID']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  unrestricted_value?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DaDataDataFinance = {
  __typename?: 'DaDataDataFinance';
  debt?: Maybe<Scalars['Float']['output']>;
  expense?: Maybe<Scalars['Float']['output']>;
  income?: Maybe<Scalars['Float']['output']>;
  penalty?: Maybe<Scalars['Float']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  tax_system?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Float']['output']>;
};

export type DaDataDataName = {
  __typename?: 'DaDataDataName';
  full?: Maybe<Scalars['String']['output']>;
  full_with_opf?: Maybe<Scalars['String']['output']>;
  latin?: Maybe<Scalars['String']['output']>;
  short?: Maybe<Scalars['String']['output']>;
  short_with_opf?: Maybe<Scalars['String']['output']>;
};

export enum EnumBroadcastAccounUserWaType {
  Block = 'BLOCK',
  Lead = 'LEAD',
  Neutral = 'NEUTRAL',
  New = 'NEW',
  Queue = 'QUEUE',
  Reject = 'REJECT',
  Replied = 'REPLIED',
  Sent = 'SENT',
  SentView = 'SENT_VIEW'
}

export enum EnumBroadcastWaStatus {
  Archive = 'ARCHIVE',
  Finish = 'FINISH',
  New = 'NEW',
  Ready = 'READY',
  Sending = 'SENDING',
  Stopped = 'STOPPED'
}

export enum EnumBroadcastWaType {
  Banned = 'BANNED',
  Boss = 'BOSS',
  Business = 'BUSINESS',
  Dealer = 'DEALER',
  Disconnected = 'DISCONNECTED',
  Harametscky = 'HARAMETSCKY',
  It = 'IT',
  Kamentsev = 'KAMENTSEV',
  Kk = 'KK',
  Kornev = 'KORNEV',
  Lipatov = 'LIPATOV',
  Loginov = 'LOGINOV',
  Mouse = 'MOUSE',
  New = 'NEW',
  Novoreg = 'NOVOREG',
  Opbd = 'OPBD',
  Parser = 'PARSER',
  Pending = 'PENDING',
  Poisk = 'POISK',
  Prod = 'PROD',
  Progrev = 'PROGREV',
  Qr = 'QR',
  Safronov = 'SAFRONOV',
  SborKp = 'SBOR_KP',
  Th = 'TH',
  Wmmail = 'WMMAIL',
  Yashin = 'YASHIN'
}

export enum EnumClientStatusWaClientStatus {
  Banned = 'BANNED',
  Boss = 'BOSS',
  Business = 'BUSINESS',
  Dealer = 'DEALER',
  Disconnected = 'DISCONNECTED',
  Harametscky = 'HARAMETSCKY',
  It = 'IT',
  Kamentsev = 'KAMENTSEV',
  Kk = 'KK',
  Kornev = 'KORNEV',
  Lipatov = 'LIPATOV',
  Loginov = 'LOGINOV',
  Mouse = 'MOUSE',
  New = 'NEW',
  Novoreg = 'NOVOREG',
  Opbd = 'OPBD',
  Parser = 'PARSER',
  Pending = 'PENDING',
  Poisk = 'POISK',
  Prod = 'PROD',
  Progrev = 'PROGREV',
  Qr = 'QR',
  Safronov = 'SAFRONOV',
  SborKp = 'SBOR_KP',
  Th = 'TH',
  Wmmail = 'WMMAIL',
  Yashin = 'YASHIN'
}

export enum EnumCompanyBroadcast_Status {
  Free = 'FREE',
  Lead = 'LEAD',
  Queue = 'QUEUE',
  Send = 'SEND'
}

export enum EnumUserType {
  Ak = 'AK',
  Client = 'CLIENT',
  Dealer = 'DEALER',
  Molchun = 'MOLCHUN',
  MopLk = 'MOP_LK',
  NoName = 'NO_NAME',
  Parts = 'PARTS',
  Rabota = 'RABOTA',
  Spam = 'SPAM',
  Torgash = 'TORGASH'
}

export enum EnumWaChatLastMessageType {
  Audio = 'audio',
  BroadcastNotification = 'broadcast_notification',
  ButtonsResponse = 'buttons_response',
  CallLog = 'call_log',
  Chat = 'chat',
  Ciphertext = 'ciphertext',
  Debug = 'debug',
  Document = 'document',
  E2eNotification = 'e2e_notification',
  Gp2 = 'gp2',
  GroupNotification = 'group_notification',
  GroupsV4Invite = 'groups_v4_invite',
  Hsm = 'hsm',
  Image = 'image',
  Interactive = 'interactive',
  List = 'list',
  ListResponse = 'list_response',
  Location = 'location',
  MultiVcard = 'multi_vcard',
  NativeFlow = 'native_flow',
  Notification = 'notification',
  NotificationTemplate = 'notification_template',
  Order = 'order',
  Oversized = 'oversized',
  Payment = 'payment',
  PollCreation = 'poll_creation',
  Product = 'product',
  Protocol = 'protocol',
  Ptt = 'ptt',
  Reaction = 'reaction',
  Revoked = 'revoked',
  Sticker = 'sticker',
  TemplateButtonReply = 'template_button_reply',
  Unknown = 'unknown',
  Vcard = 'vcard',
  Video = 'video'
}

export enum EnumWaMessageType {
  Audio = 'audio',
  BroadcastNotification = 'broadcast_notification',
  ButtonsResponse = 'buttons_response',
  CallLog = 'call_log',
  Chat = 'chat',
  Ciphertext = 'ciphertext',
  Debug = 'debug',
  Document = 'document',
  E2eNotification = 'e2e_notification',
  Gp2 = 'gp2',
  GroupNotification = 'group_notification',
  GroupsV4Invite = 'groups_v4_invite',
  Hsm = 'hsm',
  Image = 'image',
  Interactive = 'interactive',
  List = 'list',
  ListResponse = 'list_response',
  Location = 'location',
  MultiVcard = 'multi_vcard',
  NativeFlow = 'native_flow',
  Notification = 'notification',
  NotificationTemplate = 'notification_template',
  Order = 'order',
  Oversized = 'oversized',
  Payment = 'payment',
  PollCreation = 'poll_creation',
  Product = 'product',
  Protocol = 'protocol',
  Ptt = 'ptt',
  Reaction = 'reaction',
  Revoked = 'revoked',
  Sticker = 'sticker',
  TemplateButtonReply = 'template_button_reply',
  Unknown = 'unknown',
  Vcard = 'vcard',
  Video = 'video'
}

export enum EnumWhatsappStatus {
  Banned = 'BANNED',
  Boss = 'BOSS',
  Business = 'BUSINESS',
  Dealer = 'DEALER',
  Disconnected = 'DISCONNECTED',
  Harametscky = 'HARAMETSCKY',
  It = 'IT',
  Kamentsev = 'KAMENTSEV',
  Kk = 'KK',
  Kornev = 'KORNEV',
  Lipatov = 'LIPATOV',
  Loginov = 'LOGINOV',
  Mouse = 'MOUSE',
  New = 'NEW',
  Novoreg = 'NOVOREG',
  Opbd = 'OPBD',
  Parser = 'PARSER',
  Pending = 'PENDING',
  Poisk = 'POISK',
  Prod = 'PROD',
  Progrev = 'PROGREV',
  Qr = 'QR',
  Safronov = 'SAFRONOV',
  SborKp = 'SBOR_KP',
  Th = 'TH',
  Wmmail = 'WMMAIL',
  Yashin = 'YASHIN'
}

export type ErrorInterface = {
  /** Generic error message */
  message?: Maybe<Scalars['String']['output']>;
};

export type Fedresurs = {
  __typename?: 'Fedresurs';
  _id: Scalars['MongoID']['output'];
  content?: Maybe<FedresursContent>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  datePublish?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  inn?: Maybe<Scalars['String']['output']>;
  isAnnulled?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  isParticipantInSanctionList?: Maybe<Scalars['Boolean']['output']>;
  isPublisherInSanctionList?: Maybe<Scalars['Boolean']['output']>;
  messageType?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  publicationType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type FedresursContent = {
  __typename?: 'FedresursContent';
  contractInfo?: Maybe<FedresursContentContractInfo>;
  financialLeasePeriod?: Maybe<FedresursContentFinancialLeasePeriod>;
  isSubleaseContract?: Maybe<Scalars['Boolean']['output']>;
  subjects?: Maybe<Array<Maybe<FedresursContentSubjects>>>;
};

export type FedresursContentContractInfo = {
  __typename?: 'FedresursContentContractInfo';
  date?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type FedresursContentFinancialLeasePeriod = {
  __typename?: 'FedresursContentFinancialLeasePeriod';
  endDate?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type FedresursContentSubjects = {
  __typename?: 'FedresursContentSubjects';
  _id?: Maybe<Scalars['MongoID']['output']>;
  classifier?: Maybe<FedresursContentSubjectsClassifier>;
  description?: Maybe<Scalars['String']['output']>;
  guid?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
};

export type FedresursContentSubjectsClassifier = {
  __typename?: 'FedresursContentSubjectsClassifier';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

/** List of items with pagination. */
export type FedresursPagination = {
  __typename?: 'FedresursPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Fedresurs>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type FilterFindManyBroadcastAccounUserWaAccountHostOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBroadcastAccounUserWaAccountPhoneOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBroadcastAccounUserWaBroadcastIdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBroadcastAccounUserWaClientPhoneOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBroadcastAccounUserWaCompanyInnOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyBroadcastAccounUserWaInput = {
  AND?: InputMaybe<Array<FilterFindManyBroadcastAccounUserWaInput>>;
  OR?: InputMaybe<Array<FilterFindManyBroadcastAccounUserWaInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyBroadcastAccounUserWaOperatorsInput>;
  accountHost?: InputMaybe<Scalars['String']['input']>;
  accountPhone?: InputMaybe<Scalars['String']['input']>;
  broadcastId?: InputMaybe<Scalars['String']['input']>;
  clientPhone?: InputMaybe<Scalars['String']['input']>;
  companyInn?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  t?: InputMaybe<Scalars['Float']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastAccounUserWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyBroadcastAccounUserWaOperatorsInput = {
  _id?: InputMaybe<FilterFindManyBroadcastAccounUserWa_IdOperatorsInput>;
  accountHost?: InputMaybe<FilterFindManyBroadcastAccounUserWaAccountHostOperatorsInput>;
  accountPhone?: InputMaybe<FilterFindManyBroadcastAccounUserWaAccountPhoneOperatorsInput>;
  broadcastId?: InputMaybe<FilterFindManyBroadcastAccounUserWaBroadcastIdOperatorsInput>;
  clientPhone?: InputMaybe<FilterFindManyBroadcastAccounUserWaClientPhoneOperatorsInput>;
  companyInn?: InputMaybe<FilterFindManyBroadcastAccounUserWaCompanyInnOperatorsInput>;
  t?: InputMaybe<FilterFindManyBroadcastAccounUserWaTOperatorsInput>;
};

export type FilterFindManyBroadcastAccounUserWaTOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type FilterFindManyBroadcastAccounUserWa_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyBroadcastWaInput = {
  AND?: InputMaybe<Array<FilterFindManyBroadcastWaInput>>;
  OR?: InputMaybe<Array<FilterFindManyBroadcastWaInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyBroadcastWaOperatorsInput>;
  base?: InputMaybe<Scalars['String']['input']>;
  client_phones?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  countSend?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  latestMessageTime?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sender_accs?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  status?: InputMaybe<EnumBroadcastWaStatus>;
  text_greeting?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_neutral?: InputMaybe<Scalars['String']['input']>;
  text_reject?: InputMaybe<Scalars['String']['input']>;
  text_success?: InputMaybe<Scalars['String']['input']>;
  text_utp?: InputMaybe<Scalars['String']['input']>;
  text_who_are_you?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type FilterFindManyBroadcastWaNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyBroadcastWaOperatorsInput = {
  _id?: InputMaybe<FilterFindManyBroadcastWa_IdOperatorsInput>;
  name?: InputMaybe<FilterFindManyBroadcastWaNameOperatorsInput>;
};

export type FilterFindManyBroadcastWa_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyOfferInput = {
  AND?: InputMaybe<Array<FilterFindManyOfferInput>>;
  OR?: InputMaybe<Array<FilterFindManyOfferInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyOfferOperatorsInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  kp_url?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wheelbase?: InputMaybe<Scalars['String']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyOfferOperatorsInput = {
  _id?: InputMaybe<FilterFindManyOffer_IdOperatorsInput>;
};

export type FilterFindManyOffer_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyUserCompanyOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyUserInput = {
  AND?: InputMaybe<Array<FilterFindManyUserInput>>;
  OR?: InputMaybe<Array<FilterFindManyUserInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyUserOperatorsInput>;
  banned?: InputMaybe<Scalars['Boolean']['input']>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  broadcast_negative?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast_wa?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isBusinessWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  isMAII?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2ProjectId?: InputMaybe<Scalars['String']['input']>;
  isMAIIv2ProjectName?: InputMaybe<Scalars['String']['input']>;
  isWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nameWhatsApp?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tags_eye?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tlgrm_id?: InputMaybe<Scalars['Float']['input']>;
  tlgrm_premium?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<EnumUserType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificated_eye?: InputMaybe<Scalars['Boolean']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyUserOperatorsInput = {
  _id?: InputMaybe<FilterFindManyUser_IdOperatorsInput>;
  company?: InputMaybe<FilterFindManyUserCompanyOperatorsInput>;
  phone?: InputMaybe<FilterFindManyUserPhoneOperatorsInput>;
  tlgrm_id?: InputMaybe<FilterFindManyUserTlgrm_IdOperatorsInput>;
};

export type FilterFindManyUserPhoneOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyUserTlgrm_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type FilterFindManyUser_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyWaBaseInput = {
  AND?: InputMaybe<Array<FilterFindManyWaBaseInput>>;
  OR?: InputMaybe<Array<FilterFindManyWaBaseInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyWaBaseOperatorsInput>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  inns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  needUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type FilterFindManyWaBaseNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyWaBaseOperatorsInput = {
  _id?: InputMaybe<FilterFindManyWaBase_IdOperatorsInput>;
  name?: InputMaybe<FilterFindManyWaBaseNameOperatorsInput>;
};

export type FilterFindManyWaBase_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindManyWhatsappHostOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindManyWhatsappInput = {
  AND?: InputMaybe<Array<FilterFindManyWhatsappInput>>;
  OR?: InputMaybe<Array<FilterFindManyWhatsappInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindManyWhatsappOperatorsInput>;
  adbHost?: InputMaybe<Scalars['String']['input']>;
  allCount?: InputMaybe<Scalars['Float']['input']>;
  autoSeenChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  battery?: InputMaybe<Scalars['String']['input']>;
  chatsCount?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  farewellMessage?: InputMaybe<Scalars['String']['input']>;
  greetingMessage?: InputMaybe<Scalars['String']['input']>;
  history?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  host?: InputMaybe<Scalars['String']['input']>;
  messagesCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needConnectQrCode?: InputMaybe<Scalars['Boolean']['input']>;
  novoreg_date?: InputMaybe<Scalars['Date']['input']>;
  openReadChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['Float']['input']>;
  plugged?: InputMaybe<Scalars['Boolean']['input']>;
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  progrev_date?: InputMaybe<Scalars['Date']['input']>;
  progrev_good?: InputMaybe<Scalars['Boolean']['input']>;
  progrev_number?: InputMaybe<Scalars['Float']['input']>;
  qrcode?: InputMaybe<Scalars['String']['input']>;
  repliedCount?: InputMaybe<Scalars['Float']['input']>;
  retries?: InputMaybe<Scalars['Float']['input']>;
  session?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<EnumWhatsappStatus>;
  unreadCount?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wmmail_good?: InputMaybe<Scalars['Boolean']['input']>;
  wmmail_number?: InputMaybe<Scalars['Float']['input']>;
  zennoPosterHost?: InputMaybe<Scalars['String']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindManyWhatsappOperatorsInput = {
  _id?: InputMaybe<FilterFindManyWhatsapp_IdOperatorsInput>;
  host?: InputMaybe<FilterFindManyWhatsappHostOperatorsInput>;
};

export type FilterFindManyWhatsapp_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneBroadcastWaInput = {
  AND?: InputMaybe<Array<FilterFindOneBroadcastWaInput>>;
  OR?: InputMaybe<Array<FilterFindOneBroadcastWaInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneBroadcastWaOperatorsInput>;
  base?: InputMaybe<Scalars['String']['input']>;
  client_phones?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  countSend?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  latestMessageTime?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sender_accs?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  status?: InputMaybe<EnumBroadcastWaStatus>;
  text_greeting?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_neutral?: InputMaybe<Scalars['String']['input']>;
  text_reject?: InputMaybe<Scalars['String']['input']>;
  text_success?: InputMaybe<Scalars['String']['input']>;
  text_utp?: InputMaybe<Scalars['String']['input']>;
  text_who_are_you?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type FilterFindOneBroadcastWaNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneBroadcastWaOperatorsInput = {
  _id?: InputMaybe<FilterFindOneBroadcastWa_IdOperatorsInput>;
  name?: InputMaybe<FilterFindOneBroadcastWaNameOperatorsInput>;
};

export type FilterFindOneBroadcastWa_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneUserCompanyOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneUserInput = {
  AND?: InputMaybe<Array<FilterFindOneUserInput>>;
  OR?: InputMaybe<Array<FilterFindOneUserInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneUserOperatorsInput>;
  banned?: InputMaybe<Scalars['Boolean']['input']>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  broadcast_negative?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast_wa?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isBusinessWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  isMAII?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2ProjectId?: InputMaybe<Scalars['String']['input']>;
  isMAIIv2ProjectName?: InputMaybe<Scalars['String']['input']>;
  isWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nameWhatsApp?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tags_eye?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tlgrm_id?: InputMaybe<Scalars['Float']['input']>;
  tlgrm_premium?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<EnumUserType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificated_eye?: InputMaybe<Scalars['Boolean']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneUserOperatorsInput = {
  _id?: InputMaybe<FilterFindOneUser_IdOperatorsInput>;
  company?: InputMaybe<FilterFindOneUserCompanyOperatorsInput>;
  phone?: InputMaybe<FilterFindOneUserPhoneOperatorsInput>;
  tlgrm_id?: InputMaybe<FilterFindOneUserTlgrm_IdOperatorsInput>;
};

export type FilterFindOneUserPhoneOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneUserTlgrm_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type FilterFindOneUser_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneWaBaseInput = {
  AND?: InputMaybe<Array<FilterFindOneWaBaseInput>>;
  OR?: InputMaybe<Array<FilterFindOneWaBaseInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneWaBaseOperatorsInput>;
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  inns?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  models?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  needUpdate?: InputMaybe<Scalars['Boolean']['input']>;
  phones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type FilterFindOneWaBaseNameOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneWaBaseOperatorsInput = {
  _id?: InputMaybe<FilterFindOneWaBase_IdOperatorsInput>;
  name?: InputMaybe<FilterFindOneWaBaseNameOperatorsInput>;
};

export type FilterFindOneWaBase_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterFindOneWhatsappHostOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regex?: InputMaybe<Scalars['RegExpAsString']['input']>;
};

export type FilterFindOneWhatsappInput = {
  AND?: InputMaybe<Array<FilterFindOneWhatsappInput>>;
  OR?: InputMaybe<Array<FilterFindOneWhatsappInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneWhatsappOperatorsInput>;
  adbHost?: InputMaybe<Scalars['String']['input']>;
  allCount?: InputMaybe<Scalars['Float']['input']>;
  autoSeenChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  battery?: InputMaybe<Scalars['String']['input']>;
  chatsCount?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  farewellMessage?: InputMaybe<Scalars['String']['input']>;
  greetingMessage?: InputMaybe<Scalars['String']['input']>;
  history?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  host?: InputMaybe<Scalars['String']['input']>;
  messagesCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needConnectQrCode?: InputMaybe<Scalars['Boolean']['input']>;
  novoreg_date?: InputMaybe<Scalars['Date']['input']>;
  openReadChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['Float']['input']>;
  plugged?: InputMaybe<Scalars['Boolean']['input']>;
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  progrev_date?: InputMaybe<Scalars['Date']['input']>;
  progrev_good?: InputMaybe<Scalars['Boolean']['input']>;
  progrev_number?: InputMaybe<Scalars['Float']['input']>;
  qrcode?: InputMaybe<Scalars['String']['input']>;
  repliedCount?: InputMaybe<Scalars['Float']['input']>;
  retries?: InputMaybe<Scalars['Float']['input']>;
  session?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<EnumWhatsappStatus>;
  unreadCount?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wmmail_good?: InputMaybe<Scalars['Boolean']['input']>;
  wmmail_number?: InputMaybe<Scalars['Float']['input']>;
  zennoPosterHost?: InputMaybe<Scalars['String']['input']>;
};

export type FilterFindOneWhatsappMessageMediaInput = {
  AND?: InputMaybe<Array<FilterFindOneWhatsappMessageMediaInput>>;
  OR?: InputMaybe<Array<FilterFindOneWhatsappMessageMediaInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterFindOneWhatsappMessageMediaOperatorsInput>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  mymeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  serialized?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  upload?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneWhatsappMessageMediaOperatorsInput = {
  _id?: InputMaybe<FilterFindOneWhatsappMessageMedia_IdOperatorsInput>;
};

export type FilterFindOneWhatsappMessageMedia_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterFindOneWhatsappOperatorsInput = {
  _id?: InputMaybe<FilterFindOneWhatsapp_IdOperatorsInput>;
  host?: InputMaybe<FilterFindOneWhatsappHostOperatorsInput>;
};

export type FilterFindOneWhatsapp_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneNodeInput = {
  AND?: InputMaybe<Array<FilterRemoveOneNodeInput>>;
  OR?: InputMaybe<Array<FilterRemoveOneNodeInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneNodeOperatorsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneNodeOperatorsInput = {
  _id?: InputMaybe<FilterRemoveOneNode_IdOperatorsInput>;
};

export type FilterRemoveOneNode_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type FilterRemoveOneOfferInput = {
  AND?: InputMaybe<Array<FilterRemoveOneOfferInput>>;
  OR?: InputMaybe<Array<FilterRemoveOneOfferInput>>;
  _id?: InputMaybe<Scalars['MongoID']['input']>;
  /** List of *indexed* fields that can be filtered via operators. */
  _operators?: InputMaybe<FilterRemoveOneOfferOperatorsInput>;
  author?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  kp_url?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wheelbase?: InputMaybe<Scalars['String']['input']>;
};

/** For performance reason this type contains only *indexed* fields. */
export type FilterRemoveOneOfferOperatorsInput = {
  _id?: InputMaybe<FilterRemoveOneOffer_IdOperatorsInput>;
};

export type FilterRemoveOneOffer_IdOperatorsInput = {
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['MongoID']['input']>;
  gte?: InputMaybe<Scalars['MongoID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
  lt?: InputMaybe<Scalars['MongoID']['input']>;
  lte?: InputMaybe<Scalars['MongoID']['input']>;
  ne?: InputMaybe<Scalars['MongoID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['MongoID']['input']>>>;
};

export type MongoError = ErrorInterface & {
  __typename?: 'MongoError';
  /** MongoDB error code */
  code?: Maybe<Scalars['Int']['output']>;
  /** MongoDB error message */
  message?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeTypeClientBroadcast?: Maybe<Scalars['Boolean']['output']>;
  checkBroadcastChats?: Maybe<Scalars['Boolean']['output']>;
  checkBroadcastPhones?: Maybe<Scalars['Boolean']['output']>;
  checkerPhoneWhatsapp?: Maybe<Scalars['Boolean']['output']>;
  createBitrixDeal?: Maybe<BitrixDeal>;
  createBroadcast?: Maybe<CreateOneBroadcastWaPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  createBroadcastAccounUserWa?: Maybe<CreateOneBroadcastAccounUserWaPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  createNode?: Maybe<CreateOneNodePayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  createOffer?: Maybe<CreateOneOfferPayload>;
  /** Create one document with mongoose defaults, setters, hooks and validation */
  createUser?: Maybe<CreateOneUserPayload>;
  fedresurs?: Maybe<Array<Maybe<Fedresurs>>>;
  logoutSessionWA?: Maybe<Scalars['Boolean']['output']>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  removeNode?: Maybe<RemoveOneNodePayload>;
  /** Remove one document: 1) Remove with hooks via findOneAndRemove. 2) Return removed document. */
  removeOffer?: Maybe<RemoveOneOfferPayload>;
  sendMessage?: Maybe<WaMessage>;
  sendViewChat?: Maybe<Scalars['Boolean']['output']>;
  setAutoSeenWaChats?: Maybe<Whatsapp>;
  startBroadcast?: Maybe<Scalars['Boolean']['output']>;
  startSessionWA?: Maybe<Scalars['Boolean']['output']>;
  stopBroadcast?: Maybe<Scalars['Boolean']['output']>;
  stopSessionWA?: Maybe<Scalars['Boolean']['output']>;
  unSetAutoSeenWaChats?: Maybe<Whatsapp>;
  updateBroadcast?: Maybe<UpdateByIdBroadcastWaPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  updateNode?: Maybe<UpdateByIdNodePayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  updateOffer?: Maybe<UpdateByIdOfferPayload>;
  /** Update one document: 1) Retrieve one document by findById. 2) Apply updates to mongoose document. 3) Mongoose applies defaults, setters, hooks and validation. 4) And save it. */
  updateUser?: Maybe<UpdateByIdUserPayload>;
  updateWhatsapp?: Maybe<Whatsapp>;
  uploadMessageMedia?: Maybe<Scalars['String']['output']>;
};


export type MutationChangeTypeClientBroadcastArgs = {
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  broadcastId: Scalars['String']['input'];
  clientPhone: Scalars['String']['input'];
  type?: InputMaybe<EnumBroadcastAccounUserWaType>;
};


export type MutationCheckBroadcastChatsArgs = {
  broadcastId: Scalars['String']['input'];
};


export type MutationCheckBroadcastPhonesArgs = {
  broadcastId: Scalars['String']['input'];
};


export type MutationCheckerPhoneWhatsappArgs = {
  host: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationCreateBitrixDealArgs = {
  accountHost: Scalars['String']['input'];
  accountName?: InputMaybe<Scalars['String']['input']>;
  assignedById?: InputMaybe<Scalars['String']['input']>;
  broadcastName?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  clientName?: InputMaybe<Scalars['String']['input']>;
  clientPhone: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  inn?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};


export type MutationCreateBroadcastArgs = {
  record: CreateOneBroadcastWaInput;
};


export type MutationCreateBroadcastAccounUserWaArgs = {
  record: CreateOneBroadcastAccounUserWaInput;
};


export type MutationCreateNodeArgs = {
  record: CreateOneNodeInput;
};


export type MutationCreateOfferArgs = {
  record: CreateOneOfferInput;
};


export type MutationCreateUserArgs = {
  record: CreateOneUserInput;
};


export type MutationFedresursArgs = {
  inn?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLogoutSessionWaArgs = {
  host: Scalars['String']['input'];
};


export type MutationRemoveNodeArgs = {
  filter?: InputMaybe<FilterRemoveOneNodeInput>;
  sort?: InputMaybe<SortRemoveOneNodeInput>;
};


export type MutationRemoveOfferArgs = {
  filter?: InputMaybe<FilterRemoveOneOfferInput>;
  sort?: InputMaybe<SortRemoveOneOfferInput>;
};


export type MutationSendMessageArgs = {
  chatId: Scalars['String']['input'];
  content?: InputMaybe<Scalars['String']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  host: Scalars['String']['input'];
  mimetype?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendViewChatArgs = {
  chatId: Scalars['String']['input'];
  host: Scalars['String']['input'];
};


export type MutationSetAutoSeenWaChatsArgs = {
  chatId: Scalars['String']['input'];
  host: Scalars['String']['input'];
};


export type MutationStartBroadcastArgs = {
  broadcastId: Scalars['String']['input'];
};


export type MutationStartSessionWaArgs = {
  host: Scalars['String']['input'];
};


export type MutationStopBroadcastArgs = {
  broadcastId: Scalars['String']['input'];
};


export type MutationStopSessionWaArgs = {
  host: Scalars['String']['input'];
};


export type MutationUnSetAutoSeenWaChatsArgs = {
  chatId: Scalars['String']['input'];
  host: Scalars['String']['input'];
};


export type MutationUpdateBroadcastArgs = {
  _id: Scalars['MongoID']['input'];
  record: UpdateByIdBroadcastWaInput;
};


export type MutationUpdateNodeArgs = {
  _id: Scalars['MongoID']['input'];
  record: UpdateByIdNodeInput;
};


export type MutationUpdateOfferArgs = {
  _id: Scalars['MongoID']['input'];
  record: UpdateByIdOfferInput;
};


export type MutationUpdateUserArgs = {
  _id: Scalars['MongoID']['input'];
  record: UpdateByIdUserInput;
};


export type MutationUpdateWhatsappArgs = {
  host: Scalars['String']['input'];
  record: UpdateByIdWhatsappInput;
};


export type MutationUploadMessageMediaArgs = {
  host: Scalars['String']['input'];
  messageId: Scalars['String']['input'];
};

export type Node = {
  __typename?: 'Node';
  _id: Scalars['MongoID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  slotsWa?: Maybe<Array<Maybe<ClientStatus>>>;
};

export type Offer = {
  __typename?: 'Offer';
  _id: Scalars['MongoID']['output'];
  author?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  engine?: Maybe<Scalars['String']['output']>;
  kp_url?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  wheelbase?: Maybe<Scalars['String']['output']>;
};

/** List of items with pagination. */
export type OfferPagination = {
  __typename?: 'OfferPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Offer>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  currentPage: Scalars['Int']['output'];
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  pageCount?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
};

export type ProfileInfo = {
  __typename?: 'ProfileInfo';
  _id: Scalars['MongoID']['output'];
  business_info?: Maybe<ProfileInfoBusiness_Info>;
};

export type ProfileInfoBusiness_Info = {
  __typename?: 'ProfileInfoBusiness_info';
  _id?: Maybe<Scalars['MongoID']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  automatedType?: Maybe<Scalars['String']['output']>;
  businessHours?: Maybe<ProfileInfoBusiness_InfoBusinessHours>;
  categories?: Maybe<Array<Maybe<ProfileInfoBusiness_InfoCategories>>>;
  commands?: Maybe<Scalars['String']['output']>;
  commandsDescription?: Maybe<Scalars['String']['output']>;
  coverPhoto?: Maybe<Scalars['String']['output']>;
  dataSource?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fbPage?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  igProfessional?: Maybe<Scalars['JSON']['output']>;
  isProfileLinked?: Maybe<Scalars['Boolean']['output']>;
  isProfileLocked?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  profileOptions?: Maybe<ProfileInfoBusiness_InfoProfileOptions>;
  prompts?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  welcomeMsgProtocolMode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHours = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHours';
  config?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfig>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfig = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfig';
  fri?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfigFri>;
  mon?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfigMon>;
  thu?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfigThu>;
  tue?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfigTue>;
  wed?: Maybe<ProfileInfoBusiness_InfoBusinessHoursConfigWed>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfigFri = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfigFri';
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfigMon = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfigMon';
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfigThu = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfigThu';
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfigTue = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfigTue';
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoBusinessHoursConfigWed = {
  __typename?: 'ProfileInfoBusiness_infoBusinessHoursConfigWed';
  hours?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  mode?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoCategories = {
  __typename?: 'ProfileInfoBusiness_infoCategories';
  _id?: Maybe<Scalars['MongoID']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  localized_display_name?: Maybe<Scalars['String']['output']>;
};

export type ProfileInfoBusiness_InfoProfileOptions = {
  __typename?: 'ProfileInfoBusiness_infoProfileOptions';
  cartEnabled?: Maybe<Scalars['Boolean']['output']>;
  commerceExperience?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  base?: Maybe<WaBase>;
  baseList: Array<WaBase>;
  basePagination?: Maybe<WaBasePagination>;
  broadcast?: Maybe<BroadcastWa>;
  broadcastAccounUserList: Array<BroadcastAccounUserWa>;
  broadcastGetChats?: Maybe<Array<Maybe<BroadcastChats>>>;
  broadcastList: Array<BroadcastWa>;
  broadcastPagination?: Maybe<BroadcastWaPagination>;
  chatByIdWA?: Maybe<WaChat>;
  chatsWA?: Maybe<Array<Maybe<WaChat>>>;
  connectionSessionWA?: Maybe<ClientStatus>;
  connectionSessionWACount?: Maybe<Array<Maybe<WhatsappCount>>>;
  connectionSessionWAList?: Maybe<Array<Maybe<ClientStatus>>>;
  messageByChatIdWA?: Maybe<Array<Maybe<WaMessage>>>;
  messageMediaByMessageIDWA?: Maybe<WhatsappMessageMedia>;
  nodeList?: Maybe<Array<Maybe<Node>>>;
  offerPagination?: Maybe<OfferPagination>;
  user?: Maybe<User>;
  userByTypeCount?: Maybe<UserCountByType>;
  userList: Array<User>;
  userPagination?: Maybe<UserPagination>;
  whatsapp?: Maybe<Whatsapp>;
  whatsappEmailList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  whatsappList: Array<Whatsapp>;
  whatsappPagination?: Maybe<WhatsappPagination>;
};


export type QueryBaseArgs = {
  filter?: InputMaybe<FilterFindOneWaBaseInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneWaBaseInput>;
};


export type QueryBaseListArgs = {
  filter?: InputMaybe<FilterFindManyWaBaseInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyWaBaseInput>;
};


export type QueryBasePaginationArgs = {
  filter?: InputMaybe<FilterFindManyWaBaseInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyWaBaseInput>;
};


export type QueryBroadcastArgs = {
  filter?: InputMaybe<FilterFindOneBroadcastWaInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneBroadcastWaInput>;
};


export type QueryBroadcastAccounUserListArgs = {
  filter?: InputMaybe<FilterFindManyBroadcastAccounUserWaInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyBroadcastAccounUserWaInput>;
};


export type QueryBroadcastGetChatsArgs = {
  broadcastId: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  selectedClient?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBroadcastListArgs = {
  filter?: InputMaybe<FilterFindManyBroadcastWaInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyBroadcastWaInput>;
};


export type QueryBroadcastPaginationArgs = {
  filter?: InputMaybe<FilterFindManyBroadcastWaInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyBroadcastWaInput>;
};


export type QueryChatByIdWaArgs = {
  chatId: Scalars['String']['input'];
  host: Scalars['String']['input'];
};


export type QueryChatsWaArgs = {
  host?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryConnectionSessionWaArgs = {
  host: Scalars['String']['input'];
};


export type QueryConnectionSessionWaListArgs = {
  filter?: InputMaybe<FilterFindManyWhatsappInput>;
};


export type QueryMessageByChatIdWaArgs = {
  chatId: Scalars['String']['input'];
  host: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMessageMediaByMessageIdwaArgs = {
  filter?: InputMaybe<FilterFindOneWhatsappMessageMediaInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneWhatsappMessageMediaInput>;
};


export type QueryOfferPaginationArgs = {
  filter?: InputMaybe<FilterFindManyOfferInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyOfferInput>;
};


export type QueryUserArgs = {
  filter?: InputMaybe<FilterFindOneUserInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneUserInput>;
};


export type QueryUserListArgs = {
  filter?: InputMaybe<FilterFindManyUserInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyUserInput>;
};


export type QueryUserPaginationArgs = {
  filter?: InputMaybe<FilterFindManyUserInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyUserInput>;
};


export type QueryWhatsappArgs = {
  filter?: InputMaybe<FilterFindOneWhatsappInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneWhatsappInput>;
};


export type QueryWhatsappListArgs = {
  filter?: InputMaybe<FilterFindManyWhatsappInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyWhatsappInput>;
};


export type QueryWhatsappPaginationArgs = {
  filter?: InputMaybe<FilterFindManyWhatsappInput>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindManyWhatsappInput>;
};

export type RemoveOneNodePayload = {
  __typename?: 'RemoveOneNodePayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Removed document */
  record?: Maybe<Node>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RemoveOneOfferPayload = {
  __typename?: 'RemoveOneOfferPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  query?: Maybe<Query>;
  /** Removed document */
  record?: Maybe<Offer>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type RuntimeError = ErrorInterface & {
  __typename?: 'RuntimeError';
  /** Runtime error message */
  message?: Maybe<Scalars['String']['output']>;
};

export enum SortFindManyBroadcastAccounUserWaInput {
  AccounthostAsc = 'ACCOUNTHOST_ASC',
  AccounthostDesc = 'ACCOUNTHOST_DESC',
  AccountphoneAsc = 'ACCOUNTPHONE_ASC',
  AccountphoneDesc = 'ACCOUNTPHONE_DESC',
  BroadcastidAsc = 'BROADCASTID_ASC',
  BroadcastidDesc = 'BROADCASTID_DESC',
  ClientphoneAsc = 'CLIENTPHONE_ASC',
  ClientphoneDesc = 'CLIENTPHONE_DESC',
  CompanyinnAsc = 'COMPANYINN_ASC',
  CompanyinnDesc = 'COMPANYINN_DESC',
  TAsc = 'T_ASC',
  TDesc = 'T_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyBroadcastWaInput {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyFedresursInput {
  GuidAsc = 'GUID_ASC',
  GuidDesc = 'GUID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyOfferInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyUserBitrixDealInput {
  ClientphoneAsc = 'CLIENTPHONE_ASC',
  ClientphoneDesc = 'CLIENTPHONE_DESC',
  DealidAsc = 'DEALID_ASC',
  DealidDesc = 'DEALID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyUserInput {
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  TlgrmIdAsc = 'TLGRM_ID_ASC',
  TlgrmIdDesc = 'TLGRM_ID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyWaBaseInput {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindManyWhatsappInput {
  HostAsc = 'HOST_ASC',
  HostDesc = 'HOST_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneBroadcastWaInput {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneCompanyInput {
  BitrixDealIdAsc = 'BITRIX_DEAL_ID_ASC',
  BitrixDealIdDesc = 'BITRIX_DEAL_ID_DESC',
  CompanyInnAsc = 'COMPANY_INN_ASC',
  CompanyInnDesc = 'COMPANY_INN_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneUserInput {
  CompanyAsc = 'COMPANY_ASC',
  CompanyDesc = 'COMPANY_DESC',
  PhoneAsc = 'PHONE_ASC',
  PhoneDesc = 'PHONE_DESC',
  TlgrmIdAsc = 'TLGRM_ID_ASC',
  TlgrmIdDesc = 'TLGRM_ID_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneWaBaseInput {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneWhatsappInput {
  HostAsc = 'HOST_ASC',
  HostDesc = 'HOST_DESC',
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortFindOneWhatsappMessageMediaInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortRemoveOneNodeInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export enum SortRemoveOneOfferInput {
  IdAsc = '_ID_ASC',
  IdDesc = '_ID_DESC'
}

export type Subscription = {
  __typename?: 'Subscription';
  addMessageWA?: Maybe<WaMessage>;
  connectionSessionWASubscribe?: Maybe<ClientStatus>;
  offerCreated?: Maybe<Offer>;
  offerRemoved?: Maybe<Scalars['MongoID']['output']>;
  offerUpdated?: Maybe<Offer>;
};


export type SubscriptionAddMessageWaArgs = {
  host?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionConnectionSessionWaSubscribeArgs = {
  host?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateByIdBroadcastWaInput = {
  base?: InputMaybe<Scalars['String']['input']>;
  client_phones?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  countSend?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  duration?: InputMaybe<Scalars['Float']['input']>;
  latestMessageTime?: InputMaybe<Scalars['Date']['input']>;
  limit?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sender_accs?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  status?: InputMaybe<EnumBroadcastWaStatus>;
  text_greeting?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  text_neutral?: InputMaybe<Scalars['String']['input']>;
  text_reject?: InputMaybe<Scalars['String']['input']>;
  text_success?: InputMaybe<Scalars['String']['input']>;
  text_utp?: InputMaybe<Scalars['String']['input']>;
  text_who_are_you?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<EnumBroadcastWaType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
};

export type UpdateByIdBroadcastWaPayload = {
  __typename?: 'UpdateByIdBroadcastWaPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Updated document */
  record?: Maybe<BroadcastWa>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateByIdNodeInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateByIdNodePayload = {
  __typename?: 'UpdateByIdNodePayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Updated document */
  record?: Maybe<Node>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateByIdOfferInput = {
  author?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  engine?: InputMaybe<Scalars['String']['input']>;
  kp_url?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wheelbase?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateByIdOfferPayload = {
  __typename?: 'UpdateByIdOfferPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  query?: Maybe<Query>;
  /** Updated document */
  record?: Maybe<Offer>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateByIdUserInput = {
  banned?: InputMaybe<Scalars['Boolean']['input']>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  broadcast_negative?: InputMaybe<Scalars['Boolean']['input']>;
  broadcast_wa?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isBusinessWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  isMAII?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2?: InputMaybe<Scalars['Boolean']['input']>;
  isMAIIv2ProjectId?: InputMaybe<Scalars['String']['input']>;
  isMAIIv2ProjectName?: InputMaybe<Scalars['String']['input']>;
  isWhatsApp?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nameWhatsApp?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  tags_eye?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  tlgrm_id?: InputMaybe<Scalars['Float']['input']>;
  tlgrm_premium?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<EnumUserType>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  verificated_eye?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateByIdUserPayload = {
  __typename?: 'UpdateByIdUserPayload';
  /** Error that may occur during operation. If you request this field in GraphQL query, you will receive typed error in payload; otherwise error will be provided in root `errors` field of GraphQL response. */
  error?: Maybe<ErrorInterface>;
  /** Updated document */
  record?: Maybe<User>;
  /** Document ID */
  recordId?: Maybe<Scalars['MongoID']['output']>;
};

export type UpdateByIdWhatsappInput = {
  adbHost?: InputMaybe<Scalars['String']['input']>;
  allCount?: InputMaybe<Scalars['Float']['input']>;
  autoSeenChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  banned_date?: InputMaybe<Scalars['Date']['input']>;
  battery?: InputMaybe<Scalars['String']['input']>;
  chatsCount?: InputMaybe<Scalars['Float']['input']>;
  createdAt?: InputMaybe<Scalars['Date']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  farewellMessage?: InputMaybe<Scalars['String']['input']>;
  greetingMessage?: InputMaybe<Scalars['String']['input']>;
  history?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  host?: InputMaybe<Scalars['String']['input']>;
  messagesCount?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  needConnectQrCode?: InputMaybe<Scalars['Boolean']['input']>;
  novoreg_date?: InputMaybe<Scalars['Date']['input']>;
  openReadChats?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  phone?: InputMaybe<Scalars['Float']['input']>;
  plugged?: InputMaybe<Scalars['Boolean']['input']>;
  profilePicUrl?: InputMaybe<Scalars['String']['input']>;
  progrev_date?: InputMaybe<Scalars['Date']['input']>;
  progrev_good?: InputMaybe<Scalars['Boolean']['input']>;
  progrev_number?: InputMaybe<Scalars['Float']['input']>;
  qrcode?: InputMaybe<Scalars['String']['input']>;
  repliedCount?: InputMaybe<Scalars['Float']['input']>;
  retries?: InputMaybe<Scalars['Float']['input']>;
  session?: InputMaybe<Scalars['String']['input']>;
  sessionCount?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<EnumWhatsappStatus>;
  unreadCount?: InputMaybe<Scalars['Float']['input']>;
  updatedAt?: InputMaybe<Scalars['Date']['input']>;
  wmmail_good?: InputMaybe<Scalars['Boolean']['input']>;
  wmmail_number?: InputMaybe<Scalars['Float']['input']>;
  zennoPosterHost?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['MongoID']['output'];
  banned?: Maybe<Scalars['Boolean']['output']>;
  banned_date?: Maybe<Scalars['Date']['output']>;
  broadcast_negative?: Maybe<Scalars['Boolean']['output']>;
  broadcast_wa?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  company_info?: Maybe<Company>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  isBusinessWhatsApp?: Maybe<Scalars['Boolean']['output']>;
  isMAII?: Maybe<Scalars['Boolean']['output']>;
  isMAIIv2?: Maybe<Scalars['Boolean']['output']>;
  isMAIIv2ProjectId?: Maybe<Scalars['String']['output']>;
  isMAIIv2ProjectName?: Maybe<Scalars['String']['output']>;
  isWhatsApp?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  nameWhatsApp?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  tags_eye?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  timezone?: Maybe<Scalars['String']['output']>;
  tlgrm_id?: Maybe<Scalars['Float']['output']>;
  tlgrm_premium?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<EnumUserType>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  verificated_eye?: Maybe<Scalars['Boolean']['output']>;
};


export type UserCompany_InfoArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<SortFindOneCompanyInput>;
};

export type UserBitrixDeal = {
  __typename?: 'UserBitrixDeal';
  _id: Scalars['MongoID']['output'];
  clientPhone?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  dealId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

export type UserCountByType = {
  __typename?: 'UserCountByType';
  _id: Scalars['MongoID']['output'];
  akCount?: Maybe<Scalars['Float']['output']>;
  allCount?: Maybe<Scalars['Float']['output']>;
  clientCount?: Maybe<Scalars['Float']['output']>;
  dealerCount?: Maybe<Scalars['Float']['output']>;
  molchunCount?: Maybe<Scalars['Float']['output']>;
  mopLkCount?: Maybe<Scalars['Float']['output']>;
  noNameCount?: Maybe<Scalars['Float']['output']>;
  partsCount?: Maybe<Scalars['Float']['output']>;
  rabotaCount?: Maybe<Scalars['Float']['output']>;
  spamCount?: Maybe<Scalars['Float']['output']>;
  torgashCount?: Maybe<Scalars['Float']['output']>;
};

/** List of items with pagination. */
export type UserPagination = {
  __typename?: 'UserPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type ValidationError = ErrorInterface & {
  __typename?: 'ValidationError';
  /** List of validator errors */
  errors?: Maybe<Array<ValidatorError>>;
  /** Combined error message from all validators */
  message?: Maybe<Scalars['String']['output']>;
};

export type ValidatorError = {
  __typename?: 'ValidatorError';
  /** Input record idx in array which occurs the validation error. This `idx` is useful for createMany operation. For singular operations it always be 0. For *Many operations `idx` represents record index in array received from user. */
  idx: Scalars['Int']['output'];
  /** Validation error message */
  message?: Maybe<Scalars['String']['output']>;
  /** Source of the validation error from the model path */
  path?: Maybe<Scalars['String']['output']>;
  /** Field value which occurs the validation error */
  value?: Maybe<Scalars['JSON']['output']>;
};

export type WaBase = {
  __typename?: 'WaBase';
  _id: Scalars['MongoID']['output'];
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  inns?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  models?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  needUpdate?: Maybe<Scalars['Boolean']['output']>;
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  regions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
};

/** List of items with pagination. */
export type WaBasePagination = {
  __typename?: 'WaBasePagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<WaBase>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};

export type WaChat = {
  __typename?: 'WaChat';
  _id: Scalars['MongoID']['output'];
  accountPhone?: Maybe<Scalars['String']['output']>;
  archive?: Maybe<Scalars['Boolean']['output']>;
  archiveAtMentionViewedInDrawer?: Maybe<Scalars['Boolean']['output']>;
  autoSeen?: Maybe<Scalars['Boolean']['output']>;
  clientChatId?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<WaChatContact>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  ephemeralDuration?: Maybe<Scalars['Float']['output']>;
  groupMetadata?: Maybe<WaChatGroupMetadata>;
  hasChatBeenOpened?: Maybe<Scalars['Boolean']['output']>;
  hasUnreadMention?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<WaChatId>;
  isAnnounceGrpRestrict?: Maybe<Scalars['Boolean']['output']>;
  isBroadcast?: Maybe<Scalars['Boolean']['output']>;
  isGroup?: Maybe<Scalars['Boolean']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  lastMessage?: Maybe<WaChatLastMessage>;
  lastReceivedKey?: Maybe<WaChatLastReceivedKey>;
  muteExpiration?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notSpam?: Maybe<Scalars['Boolean']['output']>;
  pendingMsgs?: Maybe<Scalars['Boolean']['output']>;
  pin?: Maybe<Scalars['Float']['output']>;
  presence?: Maybe<WaChatPresence>;
  t?: Maybe<Scalars['Float']['output']>;
  uniqAccPhoneChatId?: Maybe<Scalars['String']['output']>;
  unreadCount?: Maybe<Scalars['Float']['output']>;
  unreadMentionCount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  user?: Maybe<User>;
};

export type WaChatContact = {
  __typename?: 'WaChatContact';
  _id?: Maybe<Scalars['MongoID']['output']>;
  formattedName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<WaChatId>;
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  isEnterprise?: Maybe<Scalars['Boolean']['output']>;
  isHighLevelVerified?: Maybe<Scalars['JSON']['output']>;
  isMe?: Maybe<Scalars['Boolean']['output']>;
  isMyContact?: Maybe<Scalars['Boolean']['output']>;
  isPSA?: Maybe<Scalars['Boolean']['output']>;
  isUser?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['JSON']['output']>;
  isWAContact?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  msgs?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plaintextDisabled?: Maybe<Scalars['Boolean']['output']>;
  profilePicThumbObj?: Maybe<WaChatContactProfilePicThumbObj>;
  pushname?: Maybe<Scalars['String']['output']>;
  sectionHeader?: Maybe<Scalars['JSON']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  statusMute?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  verifiedLevel?: Maybe<Scalars['JSON']['output']>;
  verifiedName?: Maybe<Scalars['JSON']['output']>;
};

export type WaChatContactProfilePicThumbObj = {
  __typename?: 'WaChatContactProfilePicThumbObj';
  _id?: Maybe<Scalars['MongoID']['output']>;
  eurl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['MongoID']['output']>;
  img?: Maybe<Scalars['String']['output']>;
  imgFull?: Maybe<Scalars['String']['output']>;
  raw?: Maybe<Scalars['JSON']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type WaChatGroupMetadata = {
  __typename?: 'WaChatGroupMetadata';
  _id?: Maybe<Scalars['MongoID']['output']>;
  announce?: Maybe<Scalars['Boolean']['output']>;
  creation?: Maybe<Scalars['Float']['output']>;
  defaultSubgroup?: Maybe<Scalars['Boolean']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  descId?: Maybe<Scalars['String']['output']>;
  descOwner?: Maybe<Scalars['MongoID']['output']>;
  descTime?: Maybe<Scalars['Float']['output']>;
  displayCadminPromotion?: Maybe<Scalars['Boolean']['output']>;
  ephemeralDuration?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<WaChatId>;
  isParentGroup?: Maybe<Scalars['Boolean']['output']>;
  noFrequentlyForwarded?: Maybe<Scalars['Boolean']['output']>;
  owner?: Maybe<Scalars['MongoID']['output']>;
  participants?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  pendingParticipants?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  restrict?: Maybe<Scalars['Boolean']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  support?: Maybe<Scalars['Boolean']['output']>;
  suspended?: Maybe<Scalars['Boolean']['output']>;
  terminated?: Maybe<Scalars['Boolean']['output']>;
};

export type WaChatId = {
  __typename?: 'WaChatId';
  _id?: Maybe<Scalars['MongoID']['output']>;
  _serialized?: Maybe<Scalars['String']['output']>;
  server?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Scalars['String']['output']>;
};

export type WaChatLastMessage = {
  __typename?: 'WaChatLastMessage';
  _id?: Maybe<Scalars['MongoID']['output']>;
  accountPhone?: Maybe<Scalars['String']['output']>;
  ack?: Maybe<Scalars['Float']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  broadcast?: Maybe<Scalars['Boolean']['output']>;
  chatId?: Maybe<Scalars['String']['output']>;
  clientChatId?: Maybe<Scalars['String']['output']>;
  clientUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  deprecatedMms3Url?: Maybe<Scalars['String']['output']>;
  directPath?: Maybe<Scalars['String']['output']>;
  filehash?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invis?: Maybe<Scalars['Boolean']['output']>;
  isForwarded?: Maybe<Scalars['Boolean']['output']>;
  isGroupMsg?: Maybe<Scalars['Boolean']['output']>;
  isMMS?: Maybe<Scalars['Boolean']['output']>;
  isMedia?: Maybe<Scalars['Boolean']['output']>;
  isNewMsg?: Maybe<Scalars['Boolean']['output']>;
  isNotification?: Maybe<Scalars['Boolean']['output']>;
  isPSA?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  mediaData?: Maybe<Scalars['JSON']['output']>;
  mediaKey?: Maybe<Scalars['String']['output']>;
  mediaKeyTimestamp?: Maybe<Scalars['Float']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  notifyName?: Maybe<Scalars['String']['output']>;
  quotedMsgId?: Maybe<Scalars['MongoID']['output']>;
  quotedMsgObj?: Maybe<Scalars['JSON']['output']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recvFresh?: Maybe<Scalars['Boolean']['output']>;
  self?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<WaChatContact>;
  size?: Maybe<Scalars['Float']['output']>;
  star?: Maybe<Scalars['Boolean']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  t?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EnumWaChatLastMessageType>;
  uniqAccPhoneChatIdMessageId?: Maybe<Scalars['String']['output']>;
  uploadhash?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type WaChatLastReceivedKey = {
  __typename?: 'WaChatLastReceivedKey';
  _id?: Maybe<Scalars['MongoID']['output']>;
  _serialized?: Maybe<Scalars['String']['output']>;
  fromMe?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  remote?: Maybe<WaChatId>;
};

export type WaChatPresence = {
  __typename?: 'WaChatPresence';
  _id?: Maybe<Scalars['MongoID']['output']>;
  chatstates?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  id?: Maybe<Scalars['MongoID']['output']>;
};

export type WaMessage = {
  __typename?: 'WaMessage';
  _id: Scalars['MongoID']['output'];
  accountPhone?: Maybe<Scalars['String']['output']>;
  ack?: Maybe<Scalars['Float']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  broadcast?: Maybe<Scalars['Boolean']['output']>;
  chatId?: Maybe<Scalars['String']['output']>;
  clientChatId?: Maybe<Scalars['String']['output']>;
  clientUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  deprecatedMms3Url?: Maybe<Scalars['String']['output']>;
  directPath?: Maybe<Scalars['String']['output']>;
  filehash?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  invis?: Maybe<Scalars['Boolean']['output']>;
  isForwarded?: Maybe<Scalars['Boolean']['output']>;
  isGroupMsg?: Maybe<Scalars['Boolean']['output']>;
  isMMS?: Maybe<Scalars['Boolean']['output']>;
  isMedia?: Maybe<Scalars['Boolean']['output']>;
  isNewMsg?: Maybe<Scalars['Boolean']['output']>;
  isNotification?: Maybe<Scalars['Boolean']['output']>;
  isPSA?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  mediaData?: Maybe<Scalars['JSON']['output']>;
  mediaKey?: Maybe<Scalars['String']['output']>;
  mediaKeyTimestamp?: Maybe<Scalars['Float']['output']>;
  mimetype?: Maybe<Scalars['String']['output']>;
  notifyName?: Maybe<Scalars['String']['output']>;
  quotedMsgId?: Maybe<Scalars['MongoID']['output']>;
  quotedMsgObj?: Maybe<Scalars['JSON']['output']>;
  recipients?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  recvFresh?: Maybe<Scalars['Boolean']['output']>;
  self?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<WaChatContact>;
  size?: Maybe<Scalars['Float']['output']>;
  star?: Maybe<Scalars['Boolean']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  t?: Maybe<Scalars['Float']['output']>;
  timestamp?: Maybe<Scalars['Float']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EnumWaMessageType>;
  uniqAccPhoneChatIdMessageId?: Maybe<Scalars['String']['output']>;
  uploadhash?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Whatsapp = {
  __typename?: 'Whatsapp';
  _id: Scalars['MongoID']['output'];
  adbHost?: Maybe<Scalars['String']['output']>;
  allCount?: Maybe<Scalars['Float']['output']>;
  autoSeenChats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  banned_date?: Maybe<Scalars['Date']['output']>;
  battery?: Maybe<Scalars['String']['output']>;
  chatsCount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  farewellMessage?: Maybe<Scalars['String']['output']>;
  greetingMessage?: Maybe<Scalars['String']['output']>;
  history?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  host?: Maybe<Scalars['String']['output']>;
  messagesCount?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  needConnectQrCode?: Maybe<Scalars['Boolean']['output']>;
  novoreg_date?: Maybe<Scalars['Date']['output']>;
  openReadChats?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  phone?: Maybe<Scalars['Float']['output']>;
  plugged?: Maybe<Scalars['Boolean']['output']>;
  profilePicUrl?: Maybe<Scalars['String']['output']>;
  progrev_date?: Maybe<Scalars['Date']['output']>;
  progrev_good?: Maybe<Scalars['Boolean']['output']>;
  progrev_number?: Maybe<Scalars['Float']['output']>;
  qrcode?: Maybe<Scalars['String']['output']>;
  repliedCount?: Maybe<Scalars['Float']['output']>;
  retries?: Maybe<Scalars['Float']['output']>;
  session?: Maybe<Scalars['String']['output']>;
  sessionCount?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<EnumWhatsappStatus>;
  unreadCount?: Maybe<Scalars['Float']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  wmmail_good?: Maybe<Scalars['Boolean']['output']>;
  wmmail_number?: Maybe<Scalars['Float']['output']>;
  zennoPosterHost?: Maybe<Scalars['String']['output']>;
};

export type WhatsappCount = {
  __typename?: 'WhatsappCount';
  _id: Scalars['MongoID']['output'];
  activeCount?: Maybe<Scalars['Float']['output']>;
  allCount?: Maybe<Scalars['Float']['output']>;
  host?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type WhatsappMessageMedia = {
  __typename?: 'WhatsappMessageMedia';
  _id: Scalars['MongoID']['output'];
  createdAt?: Maybe<Scalars['Date']['output']>;
  mymeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  serialized?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Date']['output']>;
  upload?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** List of items with pagination. */
export type WhatsappPagination = {
  __typename?: 'WhatsappPagination';
  /** Total object count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Array of objects. */
  items?: Maybe<Array<Whatsapp>>;
  /** Information to aid in pagination. */
  pageInfo: PaginationInfo;
};
